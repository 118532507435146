.header {
  position:fixed/*sticky*/; left:50%/*0*/; top:0;
  display:flex;
  width:100%; max-width:1440px;
  padding-left:8px; padding-right:8px;
  background-color:color(white-100);
  @include prefix(transform, translate(-50%), webkit moz o ms);
  z-index:90;
  
  &:before {
    content:'';
    position:absolute; left:100%; top:0;
    width:12px; height:100%;
    background-color:color(white-100);
    z-index:1;
  }
  &:after {
    content:'';
    position:absolute; left:0; bottom:0;
    width:100%; height:1px;
    background-color:color(grey-100);
    z-index:2;
  }

  .header-logo {
    position:relative;
    display:flex; float:left; align-items:center;
    width:157px;
    margin-right:0; padding:0;
    flex-shrink:0;
    z-index:2;

    @include media-breakpoint-up(lg) {
      width:190px;
      margin-right:32px;
    }
    @include media-breakpoint-up(xl) {
      width:218px;
    }

    img {
      width:auto;
      float:right;
    }
  }
  .main-menu {
    position:relative;
    display:none; float:left;
    width:100%;
    margin-right:32px; padding:14px 0;
    z-index:3;

    @include media-breakpoint-up(xl-1440) {
      display:block;
    }

    > ul {
      display:flex; justify-content:space-between;
      width:100%;

      > li {
        width:auto;

        a {
          display:block;
          float:left;
          width:auto;
          padding:8px;
          font-size:16px; line-height:1.5; font-family:$font_libre_franklin; font-weight:400; color:color(black-600);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(blue-600);

              i {
                color:color(blue-600)!important;
              }
            }
          }

          &:before {
            content:'';
            position:absolute; left:0; bottom:0;
            width:100%; height:2px;
            background-color:transparent;
            opacity:1;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }

          &.dropdown-toggle {
            display:flex; align-items:center;

            &:after {
              content:none;
            }
            i {
              display:inline-block;
              width:12px; height:12px;
              margin-left:8px;
              font-size:12px; line-height:12px; text-align:center; color:color(black-600);
              @include prefix(transition, color .3s ease-out, webkit moz o ms);
            }

            &.show {
              color:color(blue-600);

              i {
                @include prefix(transform, rotate(180deg), webkit moz o ms);
                color:color(blue-600)!important;
              }
              &:before {
                background-color:color(blue-300);
              }
            }
          }
        }

        &.dropdown {
          .dropdown-menu {
            width:auto;
            padding:0; margin-left:8px!important;
            border:0 none; border-radius:0;
            background-color:transparent;
            @include prefix(box-shadow, 0px 8px 16px 0px rgba(0,0,0,0.15), webkit moz o ms);

            li {
              position:relative;
              width:100%;
              border-bottom:1px solid color(grey-100);
              background-color:transparent;
              z-index:1;

              a {
                position:relative;
                display:block; float:left;
                width:100%;
                padding:16px 40px;
                font-size:16px; line-height:1.5; color:color(black-600); font-family:$font_libre_franklin;
                background-color:color(white);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                &:before {
                  content:'';
                  position:absolute; left:0; bottom:0;
                  width:100%; height:2px;
                  background-color:transparent;
                  opacity:1;
                  @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }

                @include media-breakpoint-up(lg) {
                  &:hover {
                    color:color(blue-600);
                  }
                }
                &:active {
                  color:color(blue-600);

                  &:before {
                    background-color:color(blue-300);
                  }
                }
              }

              &:first-child {
                a {
                  border-radius:4px 4px 0 0;
                }
              }
              &:last-child {
                border-bottom:0 none;

                a {
                  border-radius:0 0 16px 4px;
                }
              }
              &:has(> a:focus-visible) {
                z-index:2;
              }
              &.active {
                a {
                  color:color(blue-600);

                  &:before {
                    background-color:color(blue-300);
                  }
                }
              }
            }
          }
        }

        &.active {
          a {
            color:color(blue-600);

            i {
              color:color(blue-600)!important;
            }
            &:before {
              background-color:color(blue-300);
            }
          }
        }
      }
    }
  }
  .utility-menu {
    position:relative;
    float:left;
    width:auto;
    margin-left:auto; margin-right:-8px;

    > ul {
      display:flex;
      width:100%;

      > li {
        width:auto;
        padding:0;
        border-left:1px solid color(grey-100);

        > a, > span {
          position:relative;
          display:block;
          float:left;
          width:auto; min-height:56px;
          padding:16px 12px 16px 36px;
          font-size:16px; line-height:1.5; font-family:$font_libre_franklin; font-weight:400; color:color(black-600);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xl) {
            min-height:68px;
            padding:22px 12px 22px 36px;
          }

          i {
            position:absolute; left:12px; top:50%/*18px*/;
            width:24px; height:24px;
            font-size:24px; line-height:24px; color:color(black-600); text-align:center;
            @include prefix(transform, translateY(-50%), webkit moz o ms);
          }

          &:before {
            content:'';
            position:absolute; left:0; top:0;
            width:100%; height:3px;
            background-color:transparent;
            opacity:1;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(blue-600);
              background-color:color(blue-100);

              &:before {
                background-color:color(blue-200);
              }
              i {
                color:color(blue-600);
              }
            }
          }
        }
        a {
          > span {
            display:none;
            padding-left:8px;

            @include media-breakpoint-up(xl) {
              display:inline-block;
            }
          }
        }

        &.search {
          .dropdown-toggle {
            position:relative;
            z-index:3;

            &:after {
              content:'';
              position:absolute; left:50%; bottom:0;
              display:none; vertical-align:unset;
              width:0; height:0;
              margin:0;
              border-style:solid; border-width:0 12.5px 13px 12.5px; border-color:transparent transparent color(white-100) transparent;
              z-index:3;
              @include prefix(transform, translateX(-50%), webkit moz o ms);

              /*@include media-breakpoint-up(xl) {
                left:8px;
                @include prefix(transform, translateX(0), webkit moz o ms);
              }*/
            }
            &.show {
              color:color(blue-600);
              background-color:color(blue-100);

              &:before {
                background-color:color(blue-200);
              }
              &:after {
                display:block;
              }
              i {
                color:color(blue-600);
              }
            }
          }
          .dropdown-menu {
            position:fixed!important;
            flex-direction:column;
            width:100vw;
            padding:16px;
            border:0; border-radius:8px 8px 32px 8px;
            background-color:color(white-100);
            transform:translate3d(0px, 56px, 0px)!important;
            @include prefix(filter, drop-shadow(0px 2px 4px rgba(0,0,0,0.08)), webkit moz o ms);
            z-index:2;

            @include media-breakpoint-up(md) {
              position:absolute!important;
              width:320px;
            }
            @include media-breakpoint-up(xl) {
              transform:translate3d(0px, 68px, 0px)!important;
            }

            h3, .h3 {
              padding:0 16px;
              font-size:27px; line-height:1.1; font-family:$font_cormorant; font-style:italic; font-weight:500;
            }
            /*&:before {
              content:'';
              position:absolute; left:8px; top:-13px;
              width:0; height:0;
              margin:0;
              border-style:solid; border-width:0 12.5px 13px 12.5px; border-color:transparent transparent color(white-100) transparent;
            }*/
            .form-wrapper {
              padding:0;
              border:0 none;
              background-color:transparent;
              @include prefix(box-shadow, none, webkit moz o ms);

              form {
                border:0 none;

                .form-field {
                  padding:0; margin-top:16px;
                }
                .form-button {
                  width:100%;
                  margin:24px 0 0 0;

                  .btn {
                    padding:16px;
                  }
                }
              }
            }

            &.show {
              display:flex;
            }
          }
        }
        &.lang {
          display:none;
          padding:18px 32px 18px 16px;

          @include media-breakpoint-up(xl) {
            display:flex;
          }

          span, a {
            min-height:0;
            padding:4px 8px;
            text-transform:uppercase;
            background-color:transparent!important;

            &:before {
              content:none;
            }
          }
          span {
            color:color(black-600)!important; font-weight:600;
          }
          a {
            @include media-breakpoint-up(lg) {
              &:hover {
                color:color(blue-600);
              }
            }
          }
        }
        &.mobile-menu {
          a {
            width:48px; height:56px;
            padding:12px 16px;

            i {
              position:absolute; left:50%; top:50%;
              display:block; float:left;
              width:24px; height:24px;
              font-size:24px; line-height:24px; color:color(black-600); text-align:center;
              @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            }

            @include media-breakpoint-up(xl) {
              width:68px; height:68px;
              padding:18px 16px;
            }
          }

          @include media-breakpoint-up(xl-1440) {
            display:none;
          }
        }

        &.active {
          a {
            color:color(blue-600);
            background-color:color(blue-200);

            &:before {
              background-color:color(blue-300);
            }
            i {
              color:color(blue-600);
            }
          }
        }
      }
    }
  }
}

.mobile-menu-layer {
  position:fixed; right:-100vw; top:0;
  width:100%; height:100vh;
  padding:16px 16px 96px 16px;
  background-color:color(white-100);
  z-index:100;
  overflow:auto;
  @include prefix(transition, right .3s ease-out, webkit moz o ms);

  @include media-breakpoint-up(xl-1440) {
    display:none!important;
  }

  &:before {
    content:'';
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    background:rgb(209,209,209);
    background:linear-gradient(225deg, rgba(209,209,209,1) 0%, rgba(209,209,209,0.42) 17%, rgba(209,209,209,0) 100%);
    opacity:.2;
    z-index:1;
  }

  .close {
    position:fixed; right:16px; top:-100vh;
    width:36px; height:36px;
    opacity:0;
    z-index:2;
    @include prefix(transition, opacity .3s ease-out, webkit moz o ms);

    i {
      position:absolute; left:50%; top:50%;
      width:20px; height:20px;
      font-size:20px; line-height:20px; text-align:center; color:color(black-000);
      @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
    }
  }
  .lang {
    position:relative;
    display:flex;
    width:calc(100% - 50px);
    padding:8px 16px 8px 0; margin-bottom:24px;
    z-index:3;

    @include media-breakpoint-up(xl) {
      display:none;
    }

    span, a {
      min-height:0;
      padding:4px 8px;
      font-size:16px; line-height:1.5; font-family:$font_libre_franklin; font-weight:400; color:color(black-600); text-transform:uppercase;
      background-color:transparent!important;

      &:before {
        content:none;
      }
    }
    span {
      color:color(black-600)!important; font-weight:600;
    }
    a {
      @include media-breakpoint-up(lg) {
        &:hover {
          color:color(blue-600);
        }
      }
    }
  }
  nav {
    position:relative;
    display:block; float:left;
    z-index:2;

    &.mobile-menu {
      width:70%;
      border-radius:32px 0;
      border:1px solid color(black-300);
      overflow:hidden;

      ul {
        li {
          border-bottom:1px solid color(black-300);

          &:last-child {
            border-bottom:0 none;
          }
          a {
            position:relative;
            display:block;
            float:left;
            width:100%;
            padding:12px 32px;
            font-size:20px; line-height:1.5; font-family:$font_libre_franklin; font-weight:600; color:color(black-600);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
            z-index:2;

            @include media-breakpoint-up(lg) {
              &:hover {
                color:color(blue-600);

                i {
                  color:color(blue-600)!important;
                }
              }
            }

            &:before {
              content:'';
              position:absolute; left:0; bottom:0;
              width:100%; height:2px;
              background-color:transparent;
              opacity:1;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }

            &.dropdown-toggle {
              display:flex; align-items:center;

              &:after {
                content:none;
              }
              i {
                display:inline-block;
                width:12px; height:12px;
                margin-left:8px;
                font-size:12px; line-height:12px; text-align:center; color:color(black-600);
                @include prefix(transition, color .3s ease-out, webkit moz o ms);
              }

              &.show {
                color:color(blue-600);

                i {
                  @include prefix(transform, rotate(180deg), webkit moz o ms);
                  color:color(blue-600)!important;
                }
                &:before {
                  background-color:color(blue-300);
                }
              }
            }
          }

          &.dropdown {
            .dropdown-menu {
              position:relative!important;
              width:100%;
              padding:0;
              border:0 none; border-radius:0;
              background-color:transparent;
              transform:none!important;

              li {
                position:relative;
                width:100%;
                border:0 none;
                background-color:transparent;
                z-index:1;

                a {
                  position:relative;
                  display:block; float:left;
                  width:100%;
                  padding:8px 8px 8px 48px;
                  font-size:16px; line-height:1.5; color:color(black-600); font-family:$font_libre_franklin; font-weight:400;
                  @include prefix(transition, all .3s ease-out, webkit moz o ms);

                  &:before {
                    content:'';
                    position:absolute; left:0; bottom:0;
                    width:100%; height:2px;
                    background-color:transparent;
                    opacity:1;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                  }

                  @include media-breakpoint-up(lg) {
                    &:hover {
                      color:color(blue-600);
                    }
                  }
                  &:active {
                    color:color(blue-600);

                    &:before {
                      background-color:color(blue-300);
                    }
                  }
                }
                &:has(> a:focus-visible) {
                  z-index:2;
                }
                &.active {
                  a {
                    color:color(blue-600);

                    &:before {
                      background-color:color(blue-300);
                    }
                  }
                }
              }
            }
          }

          &.active {
            a {
              color:color(blue-600);

              i {
                color:color(blue-600)!important;
              }
              &:before {
                background-color:color(blue-300);
              }
            }
          }
        }
      }
    }
    &.mobile-utility {
      width:100%;
      margin-top:24px;

      ul {
        li {
          a {
            position:relative;
            display:block;
            float:left;
            width:auto;
            padding:8px;
            font-size:14px; line-height:1.5; font-family:$font_libre_franklin; font-weight:400; color:color(black-600);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(lg) {
              &:hover {
                color:color(blue-600);
              }
            }

            &:before {
              content:'';
              position:absolute; left:0; bottom:0;
              width:100%; height:2px;
              background-color:transparent;
              opacity:1;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }
          }

          &.social {
            display:flex; align-items:center; justify-content:space-between;
            padding:0 8px; margin-top:18px;

            a {
              position:relative;
              width:28px; height:28px;
              padding:0;

              &:before {
                content:none;
              }
              i {
                position:absolute; left:50%; top:50%;
                width:23px; height:23px;
                font-size:23px; line-height:23px; color:color(black-600); text-align:center;
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
              }
              @include media-breakpoint-up(lg) {
                &:hover {
                  i {
                    color:color(blue-600);
                  }
                }
              }
            }
          }
          &.active {
            a {
              color:color(blue-600);

              &:before {
                background-color:color(blue-300);
              }
            }
          }
        }
      }
    }
  }
}

body.mobile-menu-open {
  overflow:hidden;

  .mobile-menu-layer {
    right:0;

    .close {
      top:16px;
      opacity:1;
    }
  }
}
