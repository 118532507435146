.accordion {
  float:left;
  width:100%;
  
  .accordion-item {
    position:relative;
    float:left;
    width:100%;
    margin-top:8px; padding:32px;
    border:0; border-radius:8px 8px 32px 8px;
    background-color:color(white-100);
    @include prefix(box-shadow, 0px 2px 4px 0px rgba(0,0,0,0.08), webkit moz o ms);

    &:before {
      content:'';
      position:absolute; left:16px; top:16px;
      width:calc(100% - 32px); height:calc(100% - 32px);
      border:1px solid color(black,0.18); border-radius:0 0 16px 4px;
      z-index:1;
    }
    .accordion-header {
      position:relative;
      float:left;
      width:100%;
      z-index:2;

      .accordion-button {
        position:relative;
        padding:0 0 0 48px;
        border:0 none; border-radius:0;
        color:color(black)!important; font-weight:500;
        background-color:transparent;
        @include prefix(box-shadow, none, webkit moz o ms);

        &:after {
          content:none;
        }
        &:before {
          content:"\e3d6";
          position:absolute; left:0; top:50%;
          display:block;
          width:32px; height:32px;
          font-size:25px; line-height:32px; text-align:center; color:color(black-700);
          font-family:"Phosphor-Thin"!important; speak:never; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none;
          letter-spacing:0; -webkit-font-feature-settings:"liga"; -moz-font-feature-settings:"liga=1"; -moz-font-feature-settings:"liga"; -ms-font-feature-settings:"liga" 1; font-feature-settings:"liga"; -webkit-font-variant-ligatures:discretionary-ligatures; font-variant-ligatures:discretionary-ligatures;
          -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;
          @include prefix(transform, translateY(-50%), webkit moz o ms);
        }

        &[aria-expanded="true"] {
          &:before {
            content:"\e32c";
          }
        }
        &:focus-visible {
          outline:4px solid color(focus,.25); outline-offset:0;
          border-radius:0;
        }
      }
    }
    .accordion-collapse {
      position:relative;
      float:left;
      width:100%;
      padding:0 0 0 48px;
      border:0 none;
      z-index:2;

      .accordion-body {
        float:left;
        width:100%;
        padding:0;

        p {
          margin-top:8px;
        }
      }
    }
  }

  &.card-accordion {
    display:flex; flex-wrap:wrap;
    width:calc(100% + 16px);
    margin-left:-8px; margin-right:-8px;
    margin-top:-16px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(-16px - 8 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:-24px;
    }

    &:has(.accordion-button:not(.collapsed)) .accordion-header {
      opacity:0.5;
    }
    &:has(.accordion-button:not(.collapsed)) .accordion-header:has(.accordion-button:not(.collapsed)) {
      opacity:1!important;
    }

    .accordion-header {
      float:left;
      flex:1 1 calc(100% - 16px);
      margin-left:8px; margin-right:8px;
      margin-top:16px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(16px + 16 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:32px;
      }

      &.card {
        background:rgb(255,255,255);
        background:linear-gradient(219deg, rgba(255,255,255,1) 3.4%, rgba(207,226,255,1) 100%);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
      }

      .accordion-button {
        background-color:transparent;
        
        &:after {
          content:none;
        }
      }
    }
    .accordion-item {
      float:left;
      flex:1 1 calc(100% - 16px);
      padding:0; margin:0 8px;
      border:0; border-radius:0;
      background-color:transparent;
      @include prefix(box-shadow, none, webkit moz o ms);
  
      &:before {
        content:none;
      }
      .accordion-collapse {
        padding:0;
      }
    }
    
    @include media-breakpoint-up(md) {
      .accordion-header {
        flex:0 0 calc(33.33% - 16px);
        order:0;
      }
      .accordion-item {
        flex:0 0 calc(100% - 16px);
        order:1;
      }
    }
  }
}
