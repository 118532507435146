.breadcrumb {
  display:block; float:left;
  width:100%;
  margin:16px 0 0 0;
  padding:8px;

  @include media-breakpoint-up(xs-375) {
    margin:calc(16px + 4 * ((100vw - 375px) / 1065)) 0 0 0;
  }
  @include media-breakpoint-up(lg) {
    padding:8px 8px 8px 16px;
  }
  @include media-breakpoint-up(xl-1440) {
    margin:20px 0 0 0;
  }

  ul {
    padding:0 8px; margin-top:-8px;

    li {
      position:relative;
      width:auto;
      margin-top:8px; margin-right:12px; padding-right:12px;

      &:after {
        content:'';
        position:absolute; right:0; top:50%;
        width:1px; height:18px;
        border-radius:2px;
        background-color:color(black-000);
        @include prefix(transform, translate(50%,-50%), webkit moz o ms);
      }

      &:last-child {
        margin-right:0; padding-right:0;

        &:after {
          content:none;
        }
      }

      a, span {
        display:block; float:left;
        width:auto;
        font-size:16px; line-height:1.5; font-family:$font_libre_franklin; font-weight:400;
      }
      a {
        color:color(blue); text-decoration:underline!important;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          &:hover {
            color:color(black);
          }
        }
      }
      span {
        color:color(black-500);
      }
    }
  }
}
