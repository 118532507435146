/* Prefixes */

@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        #{'-' + $prefix + '-' + $property}: $value;
    }
    #{$property}: $value;
}

/* --- */

@font-face {
  font-family: 'Sentient-Regular';
  src: url('../fonts/Sentient-Regular.woff2') format('woff2'),
      url('../fonts/Sentient-Regular.woff') format('woff'),
      url('../fonts/Sentient-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

$font_libre_franklin:'Libre Franklin', sans-serif;
$font_cormorant:'Cormorant', serif;
$font_sentient:'Sentient-Regular', serif;

.font-libre-franklin { font-family:$font_libre_franklin!important; }
.font-cormorant { font-family:$font_cormorant!important; }
.font-sentient { font-family:$font_sentient!important; }

html, body {
  color:color(black); font-family:$font_sentient;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  display:block; float:left;
  width:100%;
  margin-bottom:0;
  color:color(black); letter-spacing:0;
  font-family:$font_libre_franklin; font-weight:700;
}

h1, .h1 {
  font-size:28px; line-height:1.2;

  @include media-breakpoint-up(xs-375) {
    font-size:calc(28px + 12 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:40px;
  }
}
h2, .h2 {
  position:relative;
  font-size:24px; line-height:1.2; font-weight:500;
}
h3, .h3 {
  font-size:28px; line-height:1.2;
}
h4, .h4 {
  font-size:24px; line-height:1.2; font-weight:500;
}
h5, .h5 {
  font-size:20px; line-height:1.2; font-weight:500;
}
h6, .h6 {
  font-size:16px; line-height:1.25; font-weight:500;
}

p {
  display:block; float:left;
  width:100%;
  margin-bottom:0;
  font-size:16px; line-height:1.5; color:color(black); font-family:$font_sentient;

  strong {
    font-weight:700;
  }
  a {
    color:color(blue); text-decoration:underline!important;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    @include media-breakpoint-up(lg) {
      &:hover {
        color:color(black);
      }
    }
  }
}

a {
  &.phone {
    @include media-breakpoint-up(lg) {
      cursor:default;
      pointer-events:none;
    }
  }
}
