.pagination {
  display:block; float:left;
  width:100%;
  margin:32px 0 0 0;
  padding:0 8px;
  text-align:center; font-size:0;

  ul {
    display:inline-block; float:none;
    width:auto;

    li {
      position:relative;
      width:auto;
      border:1px solid color(grey-600); border-right:0 none;
      background-color:color(white);

      &:first-child {
        border-radius:6px 0 0 6px;
      }
      &:last-child {
        border-radius:0 6px 6px 0;
        border-right:1px solid color(grey-600);
      }

      a, span {
        position:relative;
        display:block; float:left;
        width:auto;
        padding:5px 10px;
        font-size:14px; line-height:22px; font-family:$font_sentient; font-weight:400; color:color(blue); text-align:center;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          padding:calc(5px + 8 * ((100vw - 375px) / 1065)) calc(10px + 14 * ((100vw - 375px) / 1065));
          font-size:calc(14px + 6 * ((100vw - 375px) / 1065)); line-height:calc(22px + 8 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          padding:13px 24px;
          font-size:20px; line-height:30px;
        }
      }
      a {
        @include media-breakpoint-up(lg) {
          &:hover {
            background-color:color(grey-600);
          }
        }
      }

      &.arrow {
        a, span {
          padding:9px;

          i {
            display:block; float:left;
            width:14px; height:14px;
            font-size:12px; line-height:14px; text-align:center;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }

          @include media-breakpoint-up(xs-375) {
            padding:calc(9px + 9 * ((100vw - 375px) / 1065)) calc(9px + 16 * ((100vw - 375px) / 1065));

            i {
              width:calc(14px + 6 * ((100vw - 375px) / 1065)); height:calc(14px + 6 * ((100vw - 375px) / 1065));
              font-size:calc(12px + 5 * ((100vw - 375px) / 1065)); line-height:calc(14px + 6 * ((100vw - 375px) / 1065));
            }
          }
          @include media-breakpoint-up(xl-1440) {
            padding:18px 25px;

            i {
              width:20px; height:20px;
              font-size:17px; line-height:20px;
            }
          }
        }
      }
      &.active {
        a, span {
          color:color(white);
          background-color:color(blue);
        }
      }
    }
  }
}
