.footer {
  display:block; float:left;
  width:calc(100% - 16px); padding-bottom:120px;
  margin-top:32px;
  margin-right:8px; margin-left:8px;

  @include media-breakpoint-up(xl) {
    width:calc(100% + 8px);
    padding-bottom:70px;
    margin-right:-8px; margin-left:0;
  }

  .container {
    padding-left:0; padding-right:0;

    .row {
      position:relative;
      border-top:1px solid color(grey-100);
      margin-left:0; margin-right:0;

      &:last-child {
        border-bottom:1px solid color(grey-100);
      }

      > div {
        display:flex; align-items:flex-start; justify-content:flex-start; flex-wrap:wrap;
        padding-left:0; padding-right:0;

        .footer-block {
          position:relative;
          display:flex; justify-content:flex-start; align-items:flex-start; flex-direction:column; flex-wrap:wrap;
          width:100%;
          padding:16px;
          border-bottom:1px solid color(grey-100);

          @include media-breakpoint-up(lg) {
            flex-direction:row; align-items:center;
            width:auto;
            padding:16px 28px 16px 16px; margin-right:28px;
            border-bottom:0 none;

            &:after {
              content:'';
              position:absolute; right:0; top:16px;
              width:1px; height:calc(100% - 32px);
              background-color:color(grey-100);
            }
          }

          &:last-child {
            border-bottom:0 none;

            @include media-breakpoint-up(lg) {
              padding-right:16px; margin-right:0;

              &:after {
                content:none;
              }
            }
          }

          p {
            width:100%;
            margin-bottom:16px;
            font-size:14px; line-height:1.5; font-family:$font_libre_franklin; font-weight:400;

            @include media-breakpoint-up(lg) {
              width:auto;
              margin-bottom:0; margin-right:24px;
            }
          }
          ul {
            display:flex; flex-direction:row; align-items:center; justify-content:flex-start; flex-wrap:wrap;
            width:auto;
            margin-top:-12px;

            li {
              width:auto;
              margin:12px 16px 0 0;

              @include media-breakpoint-up(lg) {
                margin:12px 24px 0 0;
              }

              > a, > div {
                display:block; float:left;
                width:auto;

                figure {
                  width:auto; height:38px;

                  @include media-breakpoint-up(lg) {
                    height:45px;
                  }

                  img {
                    width:auto; height:100%;
                  }
                }
              }
            }
          }

          &.top {
            flex-wrap:nowrap;
            width:100%;

            .footer-logo {
              height:60px; width:auto;
              margin-bottom:16px;
              flex-shrink:0;

              @include media-breakpoint-up(lg) {
                margin-bottom:0; margin-right:24px;
              }

              img {
                width:auto; height:100%;
              }
            }
            > div {
              display:flex; flex-direction:column; align-items:flex-start;
              width:100%;

              @include media-breakpoint-up(lg) {
                flex-direction:column-reverse;
              }
            }
            p {
              margin:0;
              font-size:10px; line-height:1.5; color:color(black); font-family:$font_libre_franklin; font-weight:400; letter-spacing:0.6px;
            }
            .footer-menu {
              display:block; float:left;
              width:100%;
              padding:4px 0; margin-top:16px;
              border-top:1px solid color(grey-100); border-bottom:1px solid color(grey-100);

              @include media-breakpoint-up(lg) {
                width:auto;
                padding:0 16px 4px 16px;
                margin-top:0; margin-bottom:8px;
                border-top:0 none;
              }

              ul {
                margin:0 0 -8px 0;

                li {
                  margin:0 16px 8px 0;

                  @include media-breakpoint-up(xs-375) {
                    margin:0 calc(16px + 32 * ((100vw - 375px) / 1065)) 8px 0;
                  }
                  @include media-breakpoint-up(xl-1440) {
                    margin:0 48px 8px 0;
                  }

                  &:last-child {
                    margin-right:0!important;
                  }

                  a {
                    display:block; float:left;
                    width:auto;
                    font-size:14px; line-height:1.5; font-family:$font_libre_franklin; font-weight:400; color:color(black);
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);

                    @include media-breakpoint-up(lg) {
                      &:hover {
                        color:color(blue-600);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
