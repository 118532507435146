input, textarea, select {
  @include prefix(border-radius, 0!important, webkit moz o ms);
  @include prefix(appearance, none!important, webkit moz o ms);
}

.form-wrapper {
  display:block; float:left;
  width:100%;
  padding:16px;
  border-radius:8px 8px 32px 8px; border:1px solid color(black,.18);
  background-color:color(white-200);
  @include prefix(box-shadow, 0px 2px 4px 0px rgba(0,0,0,0.08), webkit moz o ms);

  form {
    display:block; float:left;
    width:100%;
    border-radius:4px 4px 16px 4px; border:1px solid color(black,.18);

    fieldset {
      display:block; float:left;
      width:100%;

      .form-head {
        display:block; float:left;
        width:100%;
        padding:8px 8px 16px 8px;
        border-bottom:1px solid color(black,.18);

        .pretitle {
          position:relative;
          float:left;
          width:calc(100% + 16px);
          padding:5px 8px 13px 48px; margin:0 -8px 16px -8px;
          border-bottom:1px solid color(black,.18);
          font-size:10px; line-height:1.4; font-family:$font_libre_franklin; font-weight:400; letter-spacing:0.6px;

          &:before {
            content:'';
            position:absolute; left:15px; top:11px;
            width:18px; height:2px;
            border-radius:2px;
            background-color:color(dark-300);
          }
        }
        h6, .h6 {
          line-height:1.2;
        }
        p {
          margin-top:8px;
          font-size:10px; line-height:1.5; font-weight:400; font-family:$font_libre_franklin;
        }
        &:has(> i) {
          padding-left:32px;
        }
        > i {
          position:absolute; left:8px; top:16px;
          width:20px; height:20px;
          font-size:16px; line-height:20px; text-align:center; color:color(black-700);
        }
      }
      .form-error {
        position:relative;
        float:left;
        width:100%;
        padding:16px 8px;
        border-bottom:1px solid color(black,.18);
        background-color:color(red-100);

        p {
          margin-top:8px;
          font-size:14px; line-height:1.5; font-weight:400; font-family:$font_libre_franklin;
        }
        &:has(> i) {
          padding-left:32px;
        }
        > i {
          position:absolute; left:8px; top:16px;
          width:20px; height:20px;
          font-size:16px; line-height:20px; text-align:center; color:color(black-700);
        }
      }
      .form-step {
        display:flex; flex-direction:column; align-items:center;
        float:left;
        width:100%;
        padding:0 8px; margin-top:32px;
        text-align:center;

        ul {
          display:flex; flex-direction:row; justify-content:center;

          li {
            position:relative;
            width:auto;
            padding:0 9px;

            &:first-child {
              padding-left:0;
            }
            &:last-child {
              padding-right:0;
            }

            &:before {
              content:'';
              position:absolute; left:0; top:50%;
              width:100%; height:1px;
              background-color:color(black-300);
            }

            span {
              position:relative;
              display:block; float:left;
              width:36px; height:32px;
              padding:4px;
              border-radius:4px 4px 8px 4px;
              border-left:2px solid color(white-200); border-right:2px solid color(white-200);
              font-size:16px; line-height:1.5; font-weight:400; font-family:$font_libre_franklin; color:color(blue-800); text-align:center;
              background-color:color(white-200);
              z-index:2;

              &:before {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:100%;
                border-radius:4px 4px 8px 4px;
                border:1px solid color(blue-200);
              }
            }

            &.active {
              span {
                color:color(white);
                background-color:color(blue);

                &:before {
                  border:1px solid color(blue);
                }
              }
            }
            &.completed {
              span {
                font-size:0; color:color(green);
                background-color:color(green);

                &:before {
                  border:1px solid color(green);
                }
                &:after {
                  content:"\e182";
                  position:relative; left:50%; top:50%;
                  display:block;
                  width:16px; height:16px;
                  font-size:13px; line-height:16px; text-align:center; color:color(black-700);
                  font-family:"Phosphor"!important; speak:never; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none;
                  letter-spacing:0; -webkit-font-feature-settings:"liga"; -moz-font-feature-settings:"liga=1"; -moz-font-feature-settings:"liga"; -ms-font-feature-settings:"liga" 1; font-feature-settings:"liga"; -webkit-font-variant-ligatures:discretionary-ligatures; font-variant-ligatures:discretionary-ligatures;
                  -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;
                  @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                }
              }
            }
          }
        }
        h5 {
          margin-top:8px;
        }
      }
      .form-field {
        position:relative;
        display:flex; flex-direction:column; align-items:flex-start;
        float:left;
        width:100%;
        padding:0 8px; margin-top:24px;

        label {
          display:block; float:left;
          width:100%;
          margin-bottom:8px;
          font-size:16px; line-height:1.5; font-weight:700; font-family:$font_sentient; color:color(black); letter-spacing:0.32px;

          &:last-child {
            margin-bottom:0;
          }

          span {
            color:color(red); font-weight:400;
          }
        }
        p {
          margin-top:8px;
          font-size:14px; line-height:1.5; font-weight:400;
        }

        input, textarea {
          display:block; float:left;
          width:100%;
          padding:10px 6px 8px 6px;
          border:1px solid transparent; border-bottom:1px solid color(black-800);
          border-radius:0;
          font-size:16px; line-height:1.5; font-weight:400; font-family:$font_sentient; color:color(black-800);
          background-color:transparent;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          &::-webkit-input-placeholder { color:color(black-800,.75); }
          &::-moz-placeholder { color:color(black-800,.75); }
          &:-ms-input-placeholder { color:color(black-800,.75); }
          &:-moz-placeholder { color:color(black-800,.75); }

          &:disabled {
            opacity:0.5;
          }
        }
        input:not([type="radio"]):not([type="checkbox"]), textarea {
          &:focus-visible {
            border-radius:6px!important;
            border-color:color(focus,.5);
            outline:0!important;
          }
        }
        textarea {
          min-height:68px;
        }
        > .selection {
          position:relative;
          float:left;
          width:100%;

          label {
            width:auto;
          }
        }
        [type="radio"]:checked, [type="radio"]:not(:checked),
        [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
          position:absolute; left:0; top:4px;
          width:16px; height:16px;
          padding:0; border:0 none;
          background-color:transparent;
        }
        [type="radio"]:checked, [type="radio"]:not(:checked) {
          &:focus-visible {
            @include prefix(border-radius, 50%!important, webkit moz o ms);
          }
        }
        [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
          &:focus-visible {
            border-radius:4px!important;
          }
        }
        [type="radio"]:checked + label, [type="radio"]:not(:checked) + label,
        [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
          position:relative;
          display:inline-block;
          padding-left:24px; margin:0;
          cursor:pointer;
          font-weight:400; letter-spacing:0;
        }
        [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
          content:'';
          position:absolute; left:0; top:4px;
          width:16px; height:16px;
          border:1px solid color(black-800);
          background-color:transparent;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }
        [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before {
          @include prefix(border-radius, 50%, webkit moz o ms);
        }
        [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
          @include prefix(border-radius, 4px, webkit moz o ms);
        }
        [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
          content:'';
          position:absolute; left:4px; top:8px;
          width:8px; height:8px;
          background-color:color(white);
          @include prefix(transform, scale(0,0), webkit moz o ms);
          @include prefix(transition, all .15s ease-out, webkit moz o ms);
          @include prefix(border-radius, 50%, webkit moz o ms);
        }
        [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
          content:"\e182";
          position:absolute; left:3px; top:7px;
          display:block;
          width:10px; height:10px;
          font-size:10px; line-height:9px; text-align:center; color:color(white);
          font-family:"Phosphor"!important; speak:never; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none;
          letter-spacing:0; -webkit-font-feature-settings:"liga"; -moz-font-feature-settings:"liga=1"; -moz-font-feature-settings:"liga"; -ms-font-feature-settings:"liga" 1; font-feature-settings:"liga"; -webkit-font-variant-ligatures:discretionary-ligatures; font-variant-ligatures:discretionary-ligatures;
          -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;
          @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
          @include prefix(transform, scale(0,0), webkit moz o ms);
          @include prefix(transition, all .15s ease-out, webkit moz o ms);
        }
        [type="radio"]:checked + label:before,
        [type="checkbox"]:checked + label:before {
          border-color:color(blue);
          background-color:color(blue);
        }
        [type="radio"]:checked + label:after,
        [type="checkbox"]:checked + label:after {
          @include prefix(transform, scale(1,1), webkit moz o ms);
        }
        [type="radio"]:checked:disabled + label, [type="radio"]:not(:checked):disabled + label,
        [type="checkbox"]:checked:disabled + label, [type="checkbox"]:not(:checked):disabled + label {
          opacity:0.5;
          cursor:default;
        }

        .custom-select {
          opacity:0;
        }

        .select2-container {
          width:100%!important;

          &.select2-container--default {
            .select2-selection--single {
              height:42px;
              padding:9px 36px 9px 6px;
              border:1px solid transparent; border-bottom:1px solid color(black-800);
              border-radius:0;
              font-size:16px; line-height:1.5; font-weight:400; font-family:$font_sentient; color:color(black-800);
              box-shadow:none;
              background-color:transparent;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);

              .select2-selection__arrow {
                position:absolute; top:0; right:0;
                height:42px; width:28px;
                background:none;

                &:before {
                  content:"\e136";
                  position:relative; left:50%; top:50%;
                  display:block;
                  width:12px; height:12px;
                  font-size:10px; line-height:12px; text-align:center; color:color(black);
                  font-family:"Phosphor"!important; speak:never; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none;
                  letter-spacing:0; -webkit-font-feature-settings:"liga"; -moz-font-feature-settings:"liga=1"; -moz-font-feature-settings:"liga"; -ms-font-feature-settings:"liga" 1; font-feature-settings:"liga"; -webkit-font-variant-ligatures:discretionary-ligatures; font-variant-ligatures:discretionary-ligatures;
                  -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;
                  @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                }
                b {
                  display:none;
                }
              }
              .select2-selection__rendered {
                color:color(black-800,.75);
                padding:0;

                &.fill {
                  color:color(black-800);
                }
              }
            }
            &.select2-container--open .select2-selection--single .select2-selection__arrow:before {
              transform:translate(-50%, -50%) rotate(180deg);
            }

            &.select2-container--focus {
              .select2-selection--single {
                border-radius:6px!important;
                border-color:color(focus,.5);
                outline:0!important;
              }
            }
          }
          &.select2-container--open {
            .select2-dropdown--above, .select2-dropdown--below {
              margin-top:0;
              padding:0;
              background:color(white);
              border:1px solid color(black,.17); border-radius:4px;

              .select2-results__options {
                padding:4px 0;
                max-height:170px;

                .select2-results__option {
                  padding:3px 5px; margin-top:0;
                  font-size:14px; line-height:1.5; color:color(black); font-family:$font_libre_franklin; font-weight:400;
                  background-color:transparent!important;

                  &.select2-results__option--selected {
                    color:color(white)!important;
                    background-color:color(blue)!important;
                  }
                  &.select2-results__option--highlighted {
                    color:color(blue);
                  }
                  &:first-child {
                    display:none;
                  }
                }
              }
            }
          }

          &--disabled {
            opacity:0.5!important;

            &.select2-container--default {
              &.select2-container--focus {
                .select2-selection--single {
                  border-radius:0!important;
                  border-color:transparent; border-bottom-color:color(black-800);
                }
              }
            }
          }
        }

        .switch {
          position:relative;
          display:inline-block;
          padding-left:40px;
          width:auto;
          font-weight:400; letter-spacing:0;
          cursor:pointer;

          input[type="checkbox"]:checked, input[type="checkbox"]:not(:checked) {
            position:absolute; left:0; top:4px;
            width:32px!important; height:16px!important;
            padding:0; border:0 none; border-radius:16px;
            background-color:transparent;

            &:checked + .slider:before {
              @include prefix(transform, translateX(16px), webkit moz o ms);
            }
            &:focus-visible {
              border-radius:16px!important;

              & + .slider {
                &:before {
                  background-color:color(focus,.5);
                }
                &:after {
                  border-color:color(focus,.5);
                }
              }
            }
          }
          &:has(input:disabled) {
            opacity:0.5;
            cursor:default;
          }
          .slider {
            position:absolute; left:0; top:4px;
            width:32px; height:16px;
            border-radius:16px;
            @include prefix(transition, all .4s ease-out, webkit moz o ms);
            cursor:pointer;

            &:before {
              content:'';
              position:absolute; left:3px; top:3px;
              height:10px; width:10px;
              border-radius:50%;
              background-color:color(black,.25);
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }
            &:after {
              content:'';
              position:absolute; left:0; top:0;
              width:100%; height:100%;
              border:1px solid color(black,.25); border-radius:16px;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }
          }
        }

        .captcha-wrapper {
          float:left;
          width:100%;
        
          img {
            max-width:286px;
          }
        }

        &.error {
          p {
            color:color(error);
          }

          input, textarea {
            padding:9px 36px 9px 6px;
            border-radius:6px!important; border-color:color(red)!important;
            background-color:color(red-100);
          }
          input:not([type="radio"]):not([type="checkbox"]), textarea {
            background-image:url(../img/error.svg); background-position: top 14px right 14px; background-size:14px 14px; background-repeat:no-repeat;

            &:focus-visible {
              outline:4px solid color(error,.25)!important;
            }
          }
          [type="radio"]:checked, [type="radio"]:not(:checked) {
            &:focus-visible {
              outline:4px solid color(error,.25)!important;
            }
          }
          [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
            &:focus-visible {
              outline:4px solid color(error,.25)!important;
            }
          }
          [type="radio"]:checked + label, [type="radio"]:not(:checked) + label,
          [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
            color:color(error); font-weight:700;
          }
          [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before,
          [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
            border:1px solid color(red);
          }
          [type="radio"]:checked + label:before,
          [type="checkbox"]:checked + label:before {
            border-color:color(red);
            background-color:color(red);
          }
          .select2-container {
            &.select2-container--default {
              .select2-selection--single {
                padding:9px 56px 9px 6px;
                border-radius:6px!important; border-color:color(red);
                background-color:color(red-100);
                background-image:url(../img/error.svg); background-position: top 14px right 30px; background-size:14px 14px; background-repeat:no-repeat;
              }
              &.select2-container--focus {
                .select2-selection--single {
                  outline:4px solid color(error,.25)!important;
                }
              }
            }
          }
          .switch {
            color:color(error);

            input[type="checkbox"]:checked, input[type="checkbox"]:not(:checked) {
              &:focus-visible {
                outline:4px solid color(error,.25)!important;

                & + .slider {
                  &:before {
                    background-color:color(red);
                  }
                  &:after {
                    border-color:color(red);
                  }
                }
              }
            }
            .slider {
              &:before {
                background-color:color(red);
              }
              &:after {
                border:1px solid color(red);
              }
            }
          }
        }

        &.success {
          p {
            color:color(success);
          }

          input, textarea {
            padding:9px 36px 9px 6px;
            border-radius:6px!important; border-color:color(green)!important;
            background-color:color(green-100);
          }
          input:not([type="radio"]):not([type="checkbox"]), textarea {
            background-image:url(../img/success.svg); background-position: top 14px right 14px; background-size:14px 14px; background-repeat:no-repeat;

            &:focus-visible {
              outline:4px solid color(success,.25)!important;
            }
          }
          [type="radio"]:checked, [type="radio"]:not(:checked) {
            &:focus-visible {
              outline:4px solid color(success,.25)!important;
            }
          }
          [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
            &:focus-visible {
              outline:4px solid color(success,.25)!important;
            }
          }
          [type="radio"]:checked + label, [type="radio"]:not(:checked) + label,
          [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
            color:color(success); font-weight:700;
          }
          [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before,
          [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
            border:1px solid color(green);
          }
          [type="radio"]:checked + label:before,
          [type="checkbox"]:checked + label:before {
            border-color:color(green);
            background-color:color(green);
          }
          .select2-container {
            &.select2-container--default {
              .select2-selection--single {
                padding:9px 56px 9px 6px;
                border-radius:6px!important; border-color:color(green);
                background-color:color(green-100);
                background-image:url(../img/success.svg); background-position: top 14px right 30px; background-size:14px 14px; background-repeat:no-repeat;
              }
              &.select2-container--focus {
                .select2-selection--single {
                  outline:4px solid color(success,.25)!important;
                }
              }
            }
          }
          .switch {
            color:color(success);

            input[type="checkbox"]:checked, input[type="checkbox"]:not(:checked) {
              &:focus-visible {
                outline:4px solid color(success,.25)!important;

                & + .slider {
                  &:before {
                    background-color:color(green);
                  }
                  &:after {
                    border-color:color(green);
                  }
                }
              }
            }
            .slider {
              &:before {
                background-color:color(green);
              }
              &:after {
                border:1px solid color(green);
              }
            }
          }
        }
      }
      .form-button {
        display:flex; flex-direction:column;
        float:left;
        width:calc(100% + 1px);
        margin:32px -0.5px -0.5px -0.5px;

        @include media-breakpoint-up(sm) {
          flex-direction:row;
        }

        .btn-base {
          width:100%;
          border-radius:0;
          margin-top:15px;

          @include media-breakpoint-up(sm) {
            margin-top:0;
          }

          &:before {
            border-radius:0;
          }

          &:first-child {
            border-radius:4px;
            margin-top:0;

            @include media-breakpoint-up(sm) {
              border-radius:0; border-bottom-left-radius:4px;
            }

            &:before {
              border-radius:4px;

              @include media-breakpoint-up(sm) {
                border-radius:0; border-bottom-left-radius:4px;
              }
            }
          }
          &:last-child {
            border-bottom-left-radius:4px; border-bottom-right-radius:16px;

            @include media-breakpoint-up(sm) {
              border-bottom-left-radius:0;
            }

            &:before {
              border-bottom-left-radius:4px; border-bottom-right-radius:16px;

              @include media-breakpoint-up(sm) {
                border-bottom-left-radius:0;
              }
            }
          }
        }

        &.full {
          width:calc(100% + 32px);
          margin:16px -16px -16px -16px;

          .btn-base {
            padding-top:16px; padding-bottom:16px;

            i {
              font-size:18px;
            }
          }
        }
      }
    }
  }

  &.content-search {
    float:left;
    width:100%;
    padding:0; margin:5px 0;
    border-radius:0; border:0 none;
    background-color:transparent;
    @include prefix(box-shadow, none, webkit moz o ms);

    form {
      padding:0; margin:0;
      border-radius:0; border:0 none;

      fieldset {
        display:flex; align-items:center; flex-direction:row;
      }
    }

    &.mt-more {
      margin-top:16px;
    }

    .form-field {
      width:100%;
      margin-top:0; padding:0;
      margin-right:16px;

      @include media-breakpoint-up(lg) {
        margin-right:32px;
      }

      input {
        padding-left:32px;
        background-image:url('../img/search.svg'); background-repeat:no-repeat; background-position:left 10px center; background-size:13px;
      }

      &.predictive-search {
        position:relative;

        .clear {
          position:absolute; right:12px; top:50%;
          width:24px; height:24px;
          border-radius:4px 4px 8px 4px;
          background-color:color(blue-200);
          @include prefix(transform, translateY(-50%), webkit moz o ms);
          z-index:2;

          i {
            position:absolute; left:50%; top:50%;
            width:12px; height:12px;
            font-size:12px; line-height:12px; color:color(black);
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
          }
        }

        input {
          padding-right:44px;
        }
      }
    }
    .form-button {
      width:auto;
      margin-top:0; padding:0;

      .btn {
        border-radius:4px!important;
      }
    }
    p {
      margin-top:8px;
      font-size:10px; font-weight:600; letter-spacing:0.6px; font-family:$font_libre_franklin;
    }
  }
}

.step-iframe {
  display:block; float:left;
  width:100%; min-height:500px;
  border:0 none;
}
