.modal-open {
  padding-right:0!important;
}

.modal {
  &.modal-card {
    .modal-dialog {
      max-width:512px;

      .modal-content {
        border:0 none;
        background-color:transparent;

        .card {
          background-color:color(white-400);

          > a, > div {
            &.form-wrapper {
              padding:0 16px;
              border-radius:0; border:0 none;
              background-color:transparent;
              @include prefix(box-shadow, none, webkit moz o ms);

              form {
                border:0 none; border-radius:0;
              }
            }
            .content {
              > div {
                &:has(> i) {
                  padding:16px 8px 16px 32px;
                }
                > i {
                  left:8px;
                  width:20px; height:20px;
                  font-size:17px; line-height:20px;
                }
                &.intro {
                  padding-right:44px;

                  .close {
                    position:absolute; right:14px; top:14px;
                    width:28px; height:28px;
                    padding:4px; border:0 none;
                    background-color:transparent;
                    opacity:.5;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);

                    i {
                      position:relative;
                      display:block; float:left;
                      width:20px; height:20px;
                      font-size:14px; line-height:20px; text-align:center; color:color(black);
                    }

                    @include media-breakpoint-up(lg) {
                      &:hover {
                        opacity:1;
                      }
                    }
                  }
                  picture {
                    width:calc(100% + 60px);
                    margin:10px -44px 16px -16px;

                    &.brd {
                      border-top:1px solid color(black,0.18); border-bottom:1px solid color(black,0.18);
                    }
                  }
                  &.img {
                    h3, .h3 {
                      margin-top:10px;

                      @include media-breakpoint-up(lg) {
                        margin-top:0;
                      }
                    }
                  }
                }
                &.py-small {
                  padding-top:8px; padding-bottom:8px;

                  .close {
                    top:6px;
                  }
                }
                p {
                  font-size:14px; font-weight:400; font-family:$font_libre_franklin;

                  &.subtitle {
                    font-size:22px; line-height:1.1; font-family:$font_cormorant; font-weight:500; font-style:italic;
                  }
                  &.font-sentient {
                    font-size:16px; line-height:1.5;
                  }
                }

                .form-step {
                  padding:0; margin-top:0; margin-bottom:16px;
          
                  ul {          
                    li {          
                      span {
                        border-left:2px solid color(white-400); border-right:2px solid color(white-400);
                        background-color:color(white-400);
                      }
                      &.active {
                        span {
                          background-color:color(blue);
                        }
                      }
                      &.completed {
                        span {
                          background-color:color(green);
                        }
                      }
                    }
                  }
                  h5 {
                    margin-top:12px;
                  }
                }
                .modal-step {
                  float:left;
                  width:100%;
                  padding:0;

                  > div {
                    float:left;
                    width:100%;
                    margin-top:10px; padding-top:22px;
                    border-top:1px solid color(dark-200);

                    &:first-child {
                      margin-top:0; padding-top:0;
                      border-top:0 none;
                    }
                  }
                  
                  .head {
                    position:relative;
                    display:flex; flex-direction:row; justify-content:space-between; align-items:flex-start;
                    width:100%;
                    margin-top:16px; margin-bottom:-4px;
                    z-index:1;

                    h6, .h6 {
                      padding-right:8px;
                    }
                    .select-all {
                      display:block; float:right;
                      font-size:14px; line-height:24px; font-weight:400; font-family:$font_libre_franklin; color:color(black); text-decoration:underline!important; white-space:nowrap;
                    }

                    &:not(:has(h5, .h5, h6, .h6)) {
                      justify-content:flex-end;
                      margin-top:24px;

                      @include media-breakpoint-up(sm) {
                        margin-bottom:-42px;
                      }

                      & + .check-list {
                        @include media-breakpoint-up(sm) {
                          width:calc(100% - 140px);
                        }
                      }
                    }
                  }
                  .check-list {
                    position:relative;
                    z-index:2;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.filter, &.filter-mobile {
    .modal-dialog {
      max-width:360px;

      /*@include media-breakpoint-down(lg) {
        margin:0 auto;
      }*/

      .modal-content {
        .card {
          > a, > div {

            &.form-wrapper {
              position:relative;

              form {
                position:relative;
                width:calc(100% + 32px); max-height:calc(100vh - 3.5rem);
                margin-left:-16px; margin-right:-16px;
                padding-left:16px; padding-right:16px;
                overflow:auto;
                z-index:1;
              }
              &::before {
                content:'';
                position:absolute; left:10px; bottom:0;
                width:calc(100% - 20px); height:16px;
                background-color:color(white-400);
                z-index:2;
              }
            }
            .content {
              > div {
                &.button {
                  position:sticky; left:0; bottom:16px;
                  width:calc(100% + 32px);
                  margin-left:-16px; margin-right:-16px;
                  padding-left:16px; padding-right:16px;
                  background-color:color(white-400);
                  overflow:visible;
                  border:0 none;

                  a, button {
                    position:relative;
                    z-index:2;

                    &:first-child {
                      border-radius:0 0 0 4px;
                    }
                    &:last-child {
                      border-radius:0 0 16px 0;
                    }
                  }
                  &::before {
                    content:'';
                    position:absolute; left:16px; bottom:0;
                    width:calc(100% - 32px); height:100%;
                    border-radius:0 0 16px 4px;
                    border:1px solid color(black,0.18)!important;
                    z-index:1;
                  }
                }
                .switch {
                  font-size:14px; line-height:24px; font-family:$font_libre_franklin; font-weight:400; color:color(black);

                  input[type="checkbox"]:checked, input[type="checkbox"]:not(:checked) {        
                    &:checked + .slider:after {
                      background-color:color(blue);
                    }
                  }
                  .slider {        
                    &:before {
                      border:1px solid color(blue);
                      background-color:color(white-400);
                      z-index:2;
                    }
                    &:after {
                      border:1px solid color(blue);
                    }
                  }
                }

                .filter-btn {
                  float:left;
                  width:100%;

                  &:last-child {
                    margin-bottom:-16px;
                  }

                  > ul {
                    > li {
                      > a, > span {
                        padding:8px 30px 8px 8px;
                      }
                    }
                  }
                }
                .filter-wrapper {
                  float:left;
                  width:100%;
                  margin-top:16px;
                }

                .audience-wrapper {
                  float:left;
                  width:100%;

                  .audience-label {
                    position:relative;
                    display:block; float:left;
                    width:auto;
                    padding:0 0 0 32px; margin:0; border:0 none; border-radius:0;
                    font-size:14px; line-height:24px; font-weight:400; font-family:$font_libre_franklin; color:color(black);
                    background-color:transparent;

                    &:before {
                      content:"\e136";
                      position:absolute; left:0; top:0;
                      display:block;
                      width:24px; height:24px;
                      font-size:17px; line-height:24px; text-align:center; color:color(black-700);
                      font-family:"Phosphor"!important; speak:never; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none;
                      letter-spacing:0; -webkit-font-feature-settings:"liga"; -moz-font-feature-settings:"liga=1"; -moz-font-feature-settings:"liga"; -ms-font-feature-settings:"liga" 1; font-feature-settings:"liga"; -webkit-font-variant-ligatures:discretionary-ligatures; font-variant-ligatures:discretionary-ligatures;
                      -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;
                    }
                  }

                  .audience-content {
                    float:left;
                    width:100%;
                  }

                  &.closed {
                    .audience-label {
                      &:before {
                        content:"\e13a";
                      }
                    }
                    .audience-content {
                      display:none;
                    }
                  }
                }

                .filter-selection {
                  float:left;
                  width:calc(100% + 32px);
                  //margin:16px -16px 8px -16px;
                  margin:0 -16px 0 -16px;
                  border-top:1px solid color(black,0.18);

                  > div {
                    float:left;
                    width:100%;
                    padding:16px;
                    border-bottom:1px solid color(black,0.18);

                    > * {
                      &:first-child {
                        margin-top:0!important;
                      }
                    }
                    .head {
                      display:flex; flex-direction:row; justify-content:space-between; align-items:flex-start;
                      width:100%;
                      margin-top:16px;

                      h5, .h5 {
                        padding-right:8px;
                      }
                      .select-all {
                        display:block; float:right;
                        font-size:14px; line-height:24px; font-weight:400; font-family:$font_libre_franklin; color:color(black); text-decoration:underline!important; white-space:nowrap;
                      }
                    }
                    .button {
                      display:flex; justify-content:space-between;
                      width:calc(100% + 16px);
                      margin:16px -8px 0 -8px;

                      .btn-base {
                        padding:6px 15px;
                        margin:0 8px;
                        font-size:16px; line-height:1.5;
                      }
                    }
                    .separator {
                      position:relative;
                      display:flex; align-items:center; justify-content:center;
                      width:100%;
                      margin-top:16px;
                      font-size:0;

                      p {
                        position:relative;
                        width:auto;
                        padding:0 16px;
                        font-size:22px; line-height:1.1; font-weight:500; font-family:$font_cormorant; color:color(black-000); font-style:italic; text-align:center;
                        background-color:color(white-400);
                        z-index:2;
                      }
                      &:before {
                        content:'';
                        position:absolute; left:50%; top:50%;
                        width:100%; height:1px;
                        background-color:color(black,0.18);
                        z-index:1;
                        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                      }
                    }
                    .search {
                      input {
                        padding-left:32px;
                        background-image:url('../img/search.svg'); background-repeat:no-repeat; background-position:left 10px center; background-size:13px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.filter-mobile {
    .modal-dialog {
      .modal-content {
        .filter-btn {
          padding-top:0; padding-bottom:0;

          &:last-child {
            margin-bottom:0!important;
          }

          .filter-selection {
            margin-top:0!important; margin-bottom:0!important;
            border-top:0 none!important;

            > div {
              &:last-child {
                border-bottom:0 none!important;
              }
            }
          }
        }
      }
    }
  }

  &.filter, &.filter-mobile, &.wizard {
    .modal-dialog {
      .modal-content {
        .card {
          > a, > div {
            .content {
              > div {
                &.button {
                  flex-direction:row;

                  .btn {
                    padding-top:8px; padding-bottom:8px;
                  }
                }
                .form-field {
                  padding:0;

                  &.mt-more {
                    margin-top:28px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.video {
    .modal-dialog {
      max-width:1080px;

      @include media-breakpoint-up(xl-1440) {
        max-width:1300px;
      }

      .modal-content {
        border-radius:8px 8px 32px 8px; border:0 none;
        padding-bottom:56.25%;
        background-color:transparent;

        iframe {
          position:absolute; left:0; top:0;
          width:100%; height:100%;
          border:0 none;
          border-radius:8px 8px 32px 8px;
        }
        .close {
          position:absolute; right:44px; top:0;
          width:44px; height:44px;
          z-index:2;
          @include prefix(transform, translate(100%,-100%), webkit moz o ms);

          @include media-breakpoint-up(xl) {
            right:0;
          }

          i {
            position:absolute; left:50%; top:50%;
            width:20px; height:20px;
            font-size:16px; line-height:20px; text-align:center; color:color(white);
            opacity:.5;
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              i {
                opacity:1;
              }
            }
          }
        }
      }
    }
  }

  &.corner {
    .modal-dialog {
      @include media-breakpoint-up(lg) {
        margin-right:26px; margin-bottom:26px;

        .modal-content {
          align-self:flex-end;
        }
      }
    }
  }
}

.modal-backdrop {
  background-color:color(black);
  opacity:0.4!important;
}

.form-wrapper {
  form {
    fieldset {
      .check-list, .radio-list {
        margin-bottom:8px;
      
        li {
          .form-field {
            margin-top:20px;
      
            [type="radio"]:checked, [type="radio"]:not(:checked),
            [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
              top:6px;
            }
            [type="radio"]:checked + label, [type="radio"]:not(:checked) + label,
            [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
              padding-left:32px;
              font-size:20px; line-height:1.5;
      
              span {
                display:block; float:left;
                width:100%;
                margin-top:0; margin-bottom:-8px;
                font-size:14px; color:color(black-800,.75);

                @include media-breakpoint-up(xs-375) {
                  margin-top:calc(0px + 8 * ((100vw - 375px) / 1065));
                }
                @include media-breakpoint-up(xl-1440) {
                  margin-top:8px;
                }
              }
            }
            [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before,
            [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
              top:6px;
            }
            [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
              top:10px;
            }
            [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
              top:9px;
            }
          }
        }
      
        &.search-result {
          padding-left:16px; padding-right:16px;
        }
      }
      .tag-list {
        width:calc(100% + 8px);
        margin-top:4px; margin-bottom:8px; margin-right:-8px;
      
        li {
          width:auto;
          margin:8px 8px 0 0;
      
          .form-field {
            margin:0;
            
            [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
              position:absolute; left:0; top:0;
              width:100%; height:100%;
              padding:0; border:0 none;
              background-color:transparent;
            }
            [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
              &:focus-visible {
                border-radius:4px!important;
              }
            }
            [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
              position:relative;
              display:inline-block;
              padding:6px 8px; margin:0;
              border-radius:2px 2px 8px 2px;
              border:1px solid color(blue,.6);
              font-size:14px; line-height:22px; color:color(blue-800); font-family:$font_libre_franklin; font-weight:400; /*white-space:nowrap;*/ letter-spacing:0;
              cursor:pointer;
              @include prefix(transition, all .15s ease-out, webkit moz o ms);
            }
            [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
              content:"\e186";
              position:absolute; left:8px; top:50%;
              display:block;
              width:10px; height:10px;
              font-size:10px; line-height:10px; text-align:center; color:color(white);
              font-family:"Phosphor"!important; speak:never; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none;
              letter-spacing:0; -webkit-font-feature-settings:"liga"; -moz-font-feature-settings:"liga=1"; -moz-font-feature-settings:"liga"; -ms-font-feature-settings:"liga" 1; font-feature-settings:"liga"; -webkit-font-variant-ligatures:discretionary-ligatures; font-variant-ligatures:discretionary-ligatures;
              -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;
              @include prefix(transform, translateY(-50%) scale(0,0), webkit moz o ms);
              @include prefix(transition, all .15s ease-out, webkit moz o ms);
            }
            [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
              content:none;
            }
            [type="checkbox"]:checked + label {
              padding-left:23px;
              border-color:color(blue);
              color:color(white);
              background-color:color(blue);
            }
            [type="checkbox"]:checked + label:before {
              @include prefix(transform, translateY(-50%) scale(1,1), webkit moz o ms);
            }
            [type="checkbox"]:checked:disabled + label, [type="checkbox"]:not(:checked):disabled + label {
              opacity:0.5;
              cursor:default;
            }
          }
        }
      }

      .filter-keyboard {
        display:flex; flex-direction:row; justify-content:center; flex-wrap:wrap;
        width:calc(100% + 24px);
        margin:4px -12px;
        font-size:0; text-align:center;

        li {
          width:auto;
          margin:4px 3.5px;

          .form-field {
            margin:0;
            
            [type="radio"]:checked, [type="radio"]:not(:checked) {
              position:absolute; left:0; top:0;
              width:100%; height:100%;
              padding:0; border:0 none;
              background-color:transparent;
            }
            [type="radio"]:checked, [type="radio"]:not(:checked) {
              &:focus-visible {
                border-radius:4px!important;
              }
            }
            [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
              position:relative;
              display:inline-block;
              min-width:32px; height:32px;
              padding:4px 10px; margin:0;
              border-radius:2px 2px 8px 2px;
              border:0 none;
              font-size:14px; line-height:24px; color:color(black-000); font-family:$font_libre_franklin; font-weight:400; white-space:nowrap; letter-spacing:0; text-align:center;
              background-color:color(white-100);
              cursor:pointer;
              @include prefix(transition, all .15s ease-out, webkit moz o ms);

              &:before {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:100%;              
                border:1px solid color(light-800);
                border-radius:2px 2px 8px 2px;
              }
            }
            [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
              content:none!important;
            }
            [type="radio"]:checked + label {
              color:color(white);
              background-color:color(blue);

              &:before {
                border-color:color(blue);
                background-color:transparent!important;
              }
            }
            [type="radio"]:checked:disabled + label, [type="radio"]:not(:checked):disabled + label {
              opacity:0.5;
              cursor:default;
            }
          }

          &:nth-child(16) {
            margin-left:16px;
          }
          &:nth-child(22) {
            margin-right:16px;
          }
        }
      }

      .conditional-fields {
        display:none; float:left;
        width:100%;
        padding-left:32px;
      
        .check-list, .radio-list {        
          li {
            .form-field {
              margin-top:16px;
            }
          }
        }
      }
    }
  }
}