.text-article {
  display:block; float:left;
  width:100%;
  margin-top:16px; margin-bottom:-16px;

  &.mt-small {
    margin-top:16px!important;
  }
  &.mt-more {
    margin-top:32px!important;
  }
  
  > * {
    &:first-child {
      margin-top:0!important;
    }
    &:last-child {
      margin-bottom:16px;
    }
  }
  p {
    float:none;
    margin-top:16px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(16px + 12 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:28px;
    }
    &.p-around {
      padding:16px 0 0 0;

      @include media-breakpoint-up(md) {
        padding:16px 16px 0 16px;
      }
    }

    &.abstract {
      font-size:20px; font-weight:400;

      strong {
        font-size:16px; color:color(blue-800); font-family:$font_cormorant; text-transform:uppercase; letter-spacing:2.4px; font-weight:700;
      }
    }
    &.fl, &.sl {
      margin-top:16px;
      font-size:27px; line-height:1.1; font-family:$font_cormorant; font-style:italic; font-weight:500;
    }
    &.sl {
      font-size:22px;
    }
    &.mt-small {
      margin-top:8px;
    }
  }
  h3, .h3, h4, .h4 {
    float:none;
    margin-top:16px;
  }
  h3, .h3 {
    font-size:27px; line-height:1.1; font-family:$font_cormorant; font-style:italic; font-weight:500;
  }
  h4, .h4 {
    font-size:22px; line-height:1.1; font-family:$font_cormorant; font-style:italic; font-weight:500;
  }
  ul, ol {
    margin-top:16px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(16px + 12 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:28px;
    }

    li {
      position:relative;
      padding-left:24px;
      font-size:16px; line-height:1.5; color:color(black); font-family:$font_sentient; font-weight:400;
    }
  }
  ul {
    li {
      &:before {
        content:'';
        position:absolute; left:10px; top:10px;
        width:4px; height:4px;
        border-radius:50%;
        background-color:color(black);
      }
    }
  }
  ol {
    counter-reset:olli;

    li {
      &:before {
        position:absolute; left:0; top:0;
        content:counter(olli, decimal)'.';
        counter-increment:olli;
        width:24px;
        font-size:16px; line-height:1.5; color:color(black); font-family:$font_sentient; font-weight:400; text-align:center;
      }
    }
  }
  .partner-list {
    display:flex; flex-direction:row; justify-content:flex-start; flex-wrap:wrap;
    float:none;
    width:100%;
    margin-right:0; margin-top:12px;

    &.p-around {
      padding:0;

      @include media-breakpoint-up(md) {
        padding:0 16px;
      }
    }

    li {
      width:auto;
      padding:0;
      margin-top:12px; margin-right:24px;

      &:before {
        content:none;
      }

      picture {
        width:95px;
        margin:0;
        border-radius:4px 4px 16px 4px;
        overflow:hidden;
      }
    }
  }
  .podcast-list {
    display:flex; flex-direction:row; justify-content:flex-start; flex-wrap:wrap;
    float:none;
    width:100%;
    margin-right:0; margin-top:0;

    &.p-around {
      padding:0;

      @include media-breakpoint-up(md) {
        padding:0 16px;
      }
    }

    li {
      display:flex; justify-content:center; align-items:center;
      width:auto; height:48px;
      padding:8px 10px;
      margin-top:8px; margin-right:16px;
      border-radius:2px 2px 8px 2px;
      background:color(black-200);

      &:before {
        content:none;
      }

      img {
        max-height:32px;
      }
    }
  }

  .partner-wrapper {
    width:100%;
    padding-bottom:16px; margin-top:24px;
    border-bottom:1px solid color(white-300);

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(24px + 24 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:48px;
    }

    p {
      margin-top:0;
      font-family:$font_libre_franklin;
    }
    ul {
      margin-top:0;
    }

    & + .partner-wrapper {
      margin-top:16px;
    }
  }
  picture {
    width:100%;
    margin-bottom:16px; margin-top:16px;
    border-radius:4px 4px 16px 4px;
    overflow:hidden;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(16px + 12 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(sm) {
      width:40%;
    }
    @include media-breakpoint-up(md) {
      width:23.3%;
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:28px;
    }

    &.half {
      @include media-breakpoint-up(lg) {
        width:50%;
      }
    }

    &.left {
      float:left;

      @include media-breakpoint-up(sm) {
        margin-right:16px;
      }
    }
    &.right {
      float:right;

      @include media-breakpoint-up(sm) {
        margin-left:16px;
      }
    }

    &:first-child {
      & + p {
        margin-top:0;
      }
    }
  }

  &.abstract {
    p {
      font-size:20px; font-weight:400;

      &:first-child {
        font-size:16px; color:color(blue-800); font-family:$font_cormorant; text-transform:uppercase; letter-spacing:2.4px; font-weight:700;
      }
    }
  }
}

.event-intro {
  display:block; float:left;
  width:100%;
  
  > div {
    float:left;
    width:100%;

    &.detail {
      padding-left:0; margin-top:-12px;

      @include media-breakpoint-up(xs-375) {
        padding-left:calc(0px + 8 * ((100vw - 375px) / 1065)); margin-top:calc(-12px + 12 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        padding-left:8px; margin-top:0;
      }

      p {
        position:relative;
        margin-top:24px;
        padding-left:40px;
        font-size:16px; line-height:1.5; font-weight:400; font-family:$font_sentient; color:color(black); font-style:italic;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(24px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:28px;
        }

        strong {
          font-weight:400; font-style:normal;
        }
        i {
          position:absolute; left:0; top:50%;
          width:32px; height:32px;
          font-size:28px; line-height:32px; text-align:center; color:color(black-400);
          @include prefix(transform, translateY(-50%), webkit moz o ms);
        }
      }
    }
    &.partner {
      margin-top:13px; padding-bottom:8px;
      border-bottom:1px solid color(grey-600);

      p {
        position:relative;
        padding:0 0 0 40px;
        font-size:10px; line-height:1.5; color:color(black-800); font-weight:400; font-family:$font_libre_franklin; letter-spacing:0.6px;
    
        &:before {
          content:'';
          position:absolute; left:11px; top:6px;
          width:18px; height:2px;
          border-radius:2px;
          background-color:color(black-800);
        }
      }
      .partner-list {
        display:flex; flex-direction:row; justify-content:flex-start; flex-wrap:wrap;
        float:none;
        width:100%;
        margin-top:2px;
    
        li {
          width:auto;
          padding:0;
          margin-top:10px; margin-right:20px;
    
          &:before {
            content:none;
          }
    
          picture {
            height:40px; width:auto;
            margin:0;
            overflow:hidden;

            img {
              height:100%; width:auto;
            }
          }
        }
      }
    }
  }
}

.book-detail {
  display:flex; flex-direction:column;

  @include media-breakpoint-up(sm) {
    flex-direction:row;
  }

  .image {
    float:left;
    width:100%;

    @include media-breakpoint-up(sm) {
      width:48.5%;
    }

    picture {
      border-radius:8px 8px 32px 8px;
      overflow:hidden;
    }
  }
  .content {
    float:left;
    width:100%;

    @include media-breakpoint-up(sm) {
      width:51.5%;
      padding-left:16px;
    }
    @include media-breakpoint-up(md) {
      margin-top:-16px;
    }

    > ul {
      > li {
        position:relative;
        padding:16px 0 0 48px;
        margin-top:16px;
        font-size:20px; line-height:1.5; font-family:$font_sentient; font-weight:400; color:color(black);

        em {
          display:block; float:left;
          width:100%;
        }

        i {
          position:absolute; left:8px; top:16px;
          width:32px; height:32px;
          font-size:28px; line-height:32px; color:color(black-400);
        }

        @include media-breakpoint-up(sm) {
          padding:0 0 0 48px;
  
          i {
            top:0;
          }
        }
        @include media-breakpoint-up(md) {
          padding:16px 0 0 48px;
  
          i {
            top:16px;
          }
        }

        &.tag-list {
          ul {
            li {        
              a, span {
                border:1px solid color(black-300);
              }
              a {
                @include media-breakpoint-up(lg) {
                  &:hover {
                    border-color:color(black);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

section {
  > .row {
    &:first-child {
      > div {
        .text-article:not(.mt-more):not(.mt-small) {
          margin-top:0!important;
        }
      }
    }
  }
}
