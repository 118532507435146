// Color variables

$color-list:(
  white:#FFFFFF,
  white-100:/*#F5F5F5*/#FEFCF5,
  white-200:#F2F2F2,
  white-300:#DCDCDC,
  white-400:#FEFBF0,
  
  black-000:#000000,
  black:#0D0D0D,
  black-100:#808080,
  black-200:#737373,
  black-300:#666666,
  black-400:#595959,
  black-500:#4d4d4d,
  black-600:#424242,
  black-700:#333333,
  black-800:#262626,
  black-900:#1A1A1A,

  grey:#B3B3B3,
  grey-100:#CCCCCC,
  grey-200:#BFBFBF,
  grey-300:#A6A6A6,
  grey-400:#999999,
  grey-500:#8C8C8C,
  grey-600:#DEE2E6,
  grey-700:#E9ECEF,
  grey-800:#F8F9FA,

  blue:#005EB8,
  blue-100:#CCDFF1,
  blue-200:#99BFE3,
  blue-300:#669ED4,
  blue-400:#337EC6,
  blue-500:#005EB8,
  blue-600:#004B93,
  blue-700:#00386E,
  blue-800:#00264A,
  blue-900:#001325,
  focus:#0D6EFD,

  fuchsia:#E93CAC,
  fuchsia-100:#FBD8EE,
  fuchsia-200:#F6B1DE,
  fuchsia-300:#F28ACD,
  fuchsia-400:#ED63BD,
  fuchsia-500:#E93CAC,
  fuchsia-600:#BA308A,
  fuchsia-700:#8C2467,
  fuchsia-800:#5D1845,
  fuchsia-900:#2F0C22,

  green:#97D700,
  green-100:#EAF7CC,
  green-200:#D5EF99,
  green-300:#C1E766,
  green-400:#ACDF33,
  green-500:#97D700,
  green-600:#79AC00,
  green-700:#5B8100,
  green-800:#3C5600,
  green-900:#1E2B00,
  green-alt:#25D366,

  azure:#41B6E6,
  azure-100:#D9F0FA,
  azure-200:#B3E2F5,
  azure-300:#8DD3F0,
  azure-400:#67C5EB,
  azure-500:#41B6E6,
  azure-600:#3492B8,
  azure-700:#276D8A,
  azure-800:#1A495C,
  azure-900:#0D242E,

  yellow:#FFC72C,
  yellow-100:#FFF4D5,
  yellow-200:#FFE9AB,
  yellow-300:#FFDD80,
  yellow-400:#FFD256,
  yellow-500:#FFC72C,
  yellow-600:#CC9F23,
  yellow-700:#99771A,
  yellow-800:#665012,
  yellow-900:#332809,

  red:#DA291C,
  red-100:#F8D4D2,
  red-200:#F0A9A4,
  red-300:#E97F77,
  red-400:#E15449,
  red-500:#DA291C,
  red-600:#AE2116,
  red-700:#831911,
  red-800:#57100B,
  red-900:#2C0806,

  light:#FFF9E5,
  light-100:#FFFEFA,
  light-200:#FFFEF5,
  light-300:#FFFBEF,
  light-400:#FFFAEA,
  light-500:#FFF9E5,
  light-600:#CCC7B7,
  light-700:#999589,
  light-800:#66645C,
  light-900:#33322E,

  dark:#33322E,
  dark-100:#D6D6D5,
  dark-200:#ADADAB,
  dark-300:#858482,
  dark-400:#5C5B58,
  dark-500:#33322E,
  dark-600:#292825,
  dark-700:#1F1E1C,
  dark-800:#141412,
  dark-900:#0A0A09,

  error:#DC3545,
  success:#198754
);

$dark-bkg:(
  blue, blue-400, blue-500, blue-600, blue-700, blue-800, blue-900,
  fuchsia, fuchsia-500, fuchsia-600, fuchsia-700, fuchsia-800, fuchsia-900,
  green-700, green-800, green-900,
  azure, azure-500, azure-600, azure-700, azure-800, azure-900,
  yellow-700, yellow-800, yellow-900,
  red, red-400, red-500, red-600, red-700, red-800, red-900,
  light-800, light-900,
  dark, dark-300, dark-400, dark-500, dark-600, dark-700, dark-800, dark-900, black
);

$light-bkg:(
  blue-100, blue-200, blue-300,
  fuchsia-100, fuchsia-200, fuchsia-300, fuchsia-400,
  green, green-100, green-200, green-300, green-400, green-500, green-600,
  azure-100, azure-200, azure-300, azure-400,
  yellow, yellow-100, yellow-200, yellow-300, yellow-400, yellow-500, yellow-600,
  red-100, red-200, red-300,
  light, light-100, light-200, light-300, light-400, light-500, light-600, light-700,
  dark-100, dark-200
);

// Color var function

@function color($color, $opacity:1) {
  $base-color:map-get($color-list, $color);
  @return rgba(red($base-color), green($base-color), blue($base-color), $opacity);
}

// Color class loop

@each $color, $value in $color-list {
  .txt-#{"" + $color} { color:$value!important; }
  .bkg-#{"" + $color} { background-color:$value!important; }

  @if index($dark-bkg, $color) {
    .bkg-#{"" + $color} {
      color:color(white)!important;

      h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p {
        color:color(white)!important;
      }
    }
    .top-banner.bkg-#{"" + $color} {
      a {
        &.close {
          i {
            color:color(white)!important;
          }
        }
      }
    }
    .brd-#{"" + $color} {
      &:before {
        border-color:color($color)!important;
      }
      > a, > div {
        .btn {
          color:color(white)!important;
          background-color:color($color);

          &:before {
            border-color:color($color)!important;
          }  
          i {
            color:color(white)!important;
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(white)!important;
              opacity:.8;

              i {
                color:color(white)!important;
              }
            }
          }
        }
      }
      > a {
        @include media-breakpoint-up(lg) {
          &:hover {
            .btn {
              color:color(white)!important;
              opacity:.8;

              i {
                color:color(white)!important;
              }
            }
          }
        }
      }
    }
    .card.bkg-#{"" + $color} {
      .content {
        > div {
          border-color:color(white)!important;
          color:color(white)!important;

          h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, > i {
            color:color(white)!important;
          }
          .pretitle {
            &:before {
              background-color:color(white)!important;
            }
          }
          &.button {
            border-color:color(white)!important;

            .btn {
              color:color(black)!important;
              background-color:color(white)!important;

              i {
                color:color(black)!important;
              }
            }
          }
        }
      }
      .image {
        .sponsor {
          p {
            color:color(white)!important;

            &:before {
              background-color:color(white)!important;
            }
          }
        }
      }

      &.wide-content, &.extra-content, &.full-content {
        > a, > div {
          .content {
            > div {
              &.button {
                border-color:color(white)!important;

                .btn {
                  color:color(white)!important;

                  i {
                    color:color(white)!important;
                  }
                }
              }
            }
          }
        }
      }
      &.full-content, &.card-content {
        > a, > div {      
          .content {
            &:before {
              border-color:color(white)!important;
            }
          }
        }
      }
      &.icon-left, &.icon-right, &.icon-top {
        > a, > div {
          .icon {
            i {
              color:color(white)!important;
            }
          }
          .content {
            > div {
              &.button {
                border-color:color(white)!important;

                .btn {
                  color:color(white)!important;

                  i {
                    color:color(white)!important;
                  }
                }
              }
            }
          }
        }
      }
      &.card-number {
        > a, > div {
          > i {
            color:color(white)!important;
          }
        }
      }
      &.card-small {
        > a, > div {
          .content {
            > div {
              &.button {    
                .btn {
                  color:color(white)!important;
    
                  i {
                    color:color(white)!important;
                  }

                  @include media-breakpoint-up(lg) {
                    &:hover {
                      color:color(white)!important;
                      opacity:.8;
        
                      i {
                        color:color(white)!important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        > a {
          @include media-breakpoint-up(lg) {
            &:hover {
              .content {
                > div {
                  &.button {    
                    .btn {
                      color:color(white)!important;
                      opacity:.8;
        
                      i {
                        color:color(white)!important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .card {
      &.full-content {
        > a, > div {
          .content {
            > div {
              &.button {
                .btn {
                  &.bkg-#{"" + $color} {
                    color:color(white)!important;
                    background-color:color($color)!important;

                    i {
                      color:color(white)!important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.bkg-card-dark {
        &.card-sponsor {
          > a, > div {
            color:color(white)!important;
  
            h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, > i {
              color:color(white)!important;
            }
            .btn {
              color:color(black)!important;
              background-color:color(white);
  
              &:before {
                border-color:color($color)!important;
              }  
              i {
                color:color(black)!important;
              }
  
              @include media-breakpoint-up(lg) {
                &:hover {
                  color:color(black)!important;
                  opacity:.8;
    
                  i {
                    color:color(black)!important;
                  }
                }
              }
            }
          }
          > a {
            @include media-breakpoint-up(lg) {
              &:hover {
                .btn {
                  color:color(black)!important;
                  opacity:.8;
    
                  i {
                    color:color(black)!important;
                  }
                }
              }
            }
          }
          &.banner {
            > a, > div {
              p {
                color:color(white-100)!important;
              }
            }
          }
        }
      }
      &.brd-card-dark {
        > a, > div {
          .btn {
            color:color(white)!important;

            &:before {
              content:none;
            }
            i {
              color:color(white)!important;
            }

            @include media-breakpoint-up(lg) {
              &:hover {
                opacity:.8;
              }
            }
          }
        }
        > a {
          @include media-breakpoint-up(lg) {
            &:hover {
              .btn {
                opacity:.8;
              }
            }
          }
        }
      }
    }
    .btn-#{"" + $color} {
      color:color($color)!important;

      &:before {
        border-color:color($color)!important;
      }
      i {
        color:color($color)!important;
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          color:color(white)!important;
          background-color:color($color)!important;

          i {
            color:color(white)!important;
          }
        }
      }

      &.btn-link {
        @include media-breakpoint-up(lg) {
          &:hover {
            color:color($color)!important;
            background-color:transparent!important;
  
            i {
              color:color($color)!important;
            }
          }
        }
      }
    }
    .btn-base.bkg-#{"" + $color} {
      color:color(white)!important;

      &:before {
        border-color:color($color)!important;
      }
      i {
        color:color(white)!important;
      }
    }
    .nav-anchor {
      ul {
        li {
          a.bkg-#{"" + $color} {
            border-color:color($color)!important;
            color:color(white)!important;

            i {
              color:color(white)!important;
            }
          }
        }
      }
    }
  } @else if index($light-bkg, $color) {
    .bkg-#{"" + $color} {
      color:color(black)!important;

      h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p {
        color:color(black)!important;
      }
    }
    .top-banner.bkg-#{"" + $color} {
      a {
        &.close {
          i {
            color:color(black)!important;
          }
        }
      }
    }
    .brd-#{"" + $color} {
      &:before {
        border-color:color($color)!important;
      }
      > a, > div {
        .btn {
          color:color(black)!important;
          background-color:color($color);

          &:before {
            border-color:color($color)!important;
          }  
          i {
            color:color(black)!important;
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(black)!important;
              opacity:.8;

              i {
                color:color(black)!important;
              }
            }
          }
        }
      }
      > a {
        @include media-breakpoint-up(lg) {
          &:hover {
            .btn {
              color:color(black)!important;
              opacity:.8;

              i {
                color:color(black)!important;
              }
            }
          }
        }
      }
    }
    .card.bkg-#{"" + $color} {
      .content {
        > div {
          border-color:color(black)!important;

          > i {
            color:color(black)!important;
          }
        }
      }

      &.wide-content, &.extra-content, &.full-content {
        > a, > div {
          .content {
            > div {
              &.button {
                border-color:color(black)!important;

                .btn {
                  color:color(black)!important;

                  i {
                    color:color(black)!important;
                  }
                }

                &.bkg-blue {
                  border-color:color(blue)!important;

                  .btn {
                    color:color(white)!important;

                    i {
                      color:color(white)!important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.full-content, &.card-content {
        > a, > div {      
          .content {
            &:before {
              border-color:color(black)!important;
            }
          }
        }
      }
      &.icon-left, &.icon-right, &.icon-top {
        > a, > div {
          .icon {
            i {
              color:color(black)!important;
            }
          }
          .content {
            > div {
              &.button {
                border-color:color(black)!important;

                .btn {
                  color:color(black)!important;

                  i {
                    color:color(black)!important;
                  }
                }
              }
            }
          }
        }
      }
      &.card-number {
        > a, > div {
          > i {
            color:color(black)!important;
          }
        }
      }      
      &.card-small {
        > a, > div {
          .content {
            > div {
              &.button {    
                .btn {
                  color:color(black)!important;
    
                  i {
                    color:color(black)!important;
                  }
                  
                  @include media-breakpoint-up(lg) {
                    &:hover {
                      color:color(blue-600)!important;
        
                      i {
                        color:color(blue-600)!important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        > a {
          @include media-breakpoint-up(lg) {
            &:hover {
              .content {
                > div {
                  &.button {    
                    .btn {
                      color:color(blue-600)!important;

                      i {
                        color:color(blue-600)!important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .card {
      &.full-content {
        > a, > div {
          .content {
            > div {
              &.button {
                .btn {
                  &.bkg-#{"" + $color} {
                    color:color(black)!important;
                    background-color:color($color)!important;

                    i {
                      color:color(black)!important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.bkg-card-light {
        &.card-sponsor {
          > a, > div {
            color:color(black)!important;
  
            h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, > i {
              color:color(black)!important;
            }
            .btn {
              color:color(white)!important;
              background-color:color(black);
  
              &:before {
                border-color:color(black)!important;
              }  
              i {
                color:color(white)!important;
              }
  
              @include media-breakpoint-up(lg) {
                &:hover {
                  color:color(white)!important;
                  opacity:.8;
    
                  i {
                    color:color(white)!important;
                  }
                }
              }
            }
          }
          > a {
            @include media-breakpoint-up(lg) {
              &:hover {
                .btn {
                  color:color(white)!important;
                  opacity:.8;
    
                  i {
                    color:color(white)!important;
                  }
                }
              }
            }
          }
          &.banner {
            > a, > div {
              p {
                color:color(black)!important;
              }
            }
          }
        }
      }
      &.brd-card-light {
        > a, > div {
          .btn {
            color:color(black)!important;

            &:before {
              content:none;
            }
            i {
              color:color(black)!important;
            }

            @include media-breakpoint-up(lg) {
              &:hover {
                opacity:.8;
              }
            }
          }
        }
        > a {
          @include media-breakpoint-up(lg) {
            &:hover {
              .btn {
                opacity:.8;
              }
            }
          }
        }
      }
    }
    .btn-#{"" + $color} {
      color:color($color)!important;

      &:before {
        border-color:color($color)!important;
      }
      i {
        color:color($color)!important;
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          color:color(black)!important;
          background-color:color($color)!important;

          i {
            color:color(black)!important;
          }
        }
      }

      &.btn-link {
        @include media-breakpoint-up(lg) {
          &:hover {
            color:color($color)!important;
            background-color:transparent!important;
  
            i {
              color:color($color)!important;
            }
          }
        }
      }
    }
    .btn-base.bkg-#{"" + $color} {
      color:color(black)!important;

      &:before {
        border-color:color($color)!important;
      }
      i {
        color:color(black)!important;
      }
    }
    .nav-anchor {
      ul {
        li {
          a.bkg-#{"" + $color} {
            border-color:color($color)!important;
            color:color(black)!important;

            i {
              color:color(black)!important;
            }
          }
        }
      }
    }
  }
}
