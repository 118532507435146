.card-wrapper {
  > div {
    margin-top:16px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(16px + 16 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:32px;
    }

    &.mt-small {
      margin-top:8px;
    }
  }

  &.number-wrapper {
    margin-top:-16px;

    > div {
      margin-top:16px;
    }
  }
  &.mt-small {
    margin-top:-8px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(-8px - 16 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:-24px;
    }
  }
}

.card-list-wrapper {
  > div {
    margin-top:16px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(16px + 8 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:24px;
    }
  }
  &.mt-small {
    margin-top:-8px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(-8px - 8 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:-16px;
    }
  }
}

.list-divider {
  float:left;
  width:100%;
  padding:8px 16px;
  border-radius:2px 2px 8px 2px;
  background-color:color(blue-100);
  font-size:16px; line-height:1.5; font-weight:400; font-family:$font_libre_franklin; color:color(black);
}

.card-nav {
  .card {
    background:rgb(255,255,255);
    background:linear-gradient(219deg, rgba(255,255,255,1) 3.4%, rgba(207,226,255,1) 100%);
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    &.active {
      opacity:1!important;
    }
  }

  &.active {
    .card {
      opacity:.5;
    }
  }
}
.card-nav-content-wrapper {
  .card-nav-content {
    .section-head {
      text-align:left;
    }
    ul {
      display:flex; flex-direction:row; flex-wrap:wrap; justify-content:flex-start;
      margin-top:-8px;

      li {
        flex:0 0 1;

        @include media-breakpoint-up(sm) {
          flex:0 0 calc(((99.8% - 32px) / 12) * 6);
        }
        @include media-breakpoint-up(lg) {
          flex:0 0 calc(((99.8% - 32px) / 10) * 3);
        }
      }

      &.sm-row {
        li:nth-child(2n) {
          @include media-breakpoint-up(lg) {
            margin-right:30%;
          }
        }
      }
    }

    &.left {
      ul {
        li {
          margin:24px 0 0 0;

          @include media-breakpoint-up(sm) {
            margin:24px 16px 0 0;
          }
        }
      }
    }
    &.center {
      .section-head {
        @include media-breakpoint-up(lg) {
          text-align:center;
        }
      }
      ul {
        @include media-breakpoint-up(lg) {
          justify-content:center;
        }

        li {
          margin:24px 0 0 0;

          @include media-breakpoint-up(sm) {
            margin:24px 8px 0 8px;
          }
        }
      }
    }
    &.right {
      .section-head {
        @include media-breakpoint-up(lg) {
          text-align:right;
        }
      }
      ul {
        @include media-breakpoint-up(lg) {
          justify-content:flex-end;
        }

        li {
          margin:24px 0 0 0;

          @include media-breakpoint-up(sm) {
            margin:24px 0 0 16px;
          }
        }
      }
    }
  }
}

section {
  > .row:first-child {
    &.card-wrapper {
      margin-top:-16px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(-16px - 16 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:-32px;
      }
    }

    &.card-list-wrapper {
      margin-top:-16px;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(-16px - 8 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:-24px;
      }
    }
  }
}

.card {
  display:flex; flex-direction:row; align-items:center;
  width:100%; height:100%;
  border:0;
  border-radius:4px 4px 16px 4px;
  background-color:color(white-100);
  background-position:center; background-repeat:no-repeat; background-size:cover;
  @include prefix(box-shadow, 0px 2px 4px 0px rgba(0,0,0,0.08), webkit moz o ms);
  overflow:hidden;

  @include media-breakpoint-up(lg) {
    border-radius:8px 8px 32px 8px;
  }

  &.no-sdw {
    @include prefix(box-shadow, none, webkit moz o ms);
  }

  > a, > div, > button {
    display:flex; flex-direction:column-reverse; align-items:center; justify-content:flex-end;
    width:100%; height:100%;
    padding:0 16px;

    @include media-breakpoint-up(xs-375) {
      padding:0 calc(16px + 48 * ((100vw - 375px) / 825));
    }
    @include media-breakpoint-up(lg) {
      flex-direction:row; justify-content:flex-start;
      min-height:512px;
    }
    @include media-breakpoint-up(xl) {
      padding:0 64px;
    }
    
    .content {
      display:block; float:left;
      width:100%;
      margin:16px 0;
      flex-shrink:0;

      @include media-breakpoint-up(lg) {
        width:29.7%;
        margin:16px 16px 16px 0;
      }

      > div {
        position:relative;
        float:left;
        width:100%;
        padding:16px;
        border:1px solid color(black,0.18); border-bottom:0 none;

        &:has(> i) {
          padding:16px 16px 16px 64px;
        }

        &:first-child {
          border-radius:4px 4px 0 0;
        }
        &:last-child {
          border-radius:0 0 16px 4px;
          border-bottom:1px solid color(black,0.18);
          overflow:hidden;
        }

        > i {
          position:absolute; left:16px; top:16px;
          width:32px; height:32px;
          font-size:27px; line-height:32px; text-align:center;
        }
        > i {
          & + p {
            margin-top:0;
          }
        }
        p {
          margin-top:8px;

          a {
            color:inherit;
          }

          &:first-child {
            margin-top:0!important;
          }
        }

        .pretitle {
          position:relative;
          display:block;
          padding:4px 0 4px 40px;
          font-size:10px; line-height:1.6; font-family:$font_libre_franklin; font-weight:400; letter-spacing:0.6px;

          &:before {
            content:'';
            position:absolute; left:11px; top:12px;
            width:18px; height:1px;
            border-radius:2px;
            background-color:color(black-400);
          }
          i {
            position:absolute; left:8px; top:0;
            width:24px; height:24px;
            font-size:20px; line-height:24px; text-align:center; color:color(black-400);
          }
          &:has(> i) {
            &:before {
              content:none;
            }
          }
        }

        &.intro {
          h2, .h2 {
            font-size:32px; font-weight:700;
          }
          p {
            margin-top:16px;

            &.subtitle {
              margin-top:8px;
              font-size:22px; line-height:1.1; font-family:$font_cormorant; font-weight:500; font-style:italic;
            }
          }
        }
        &.button {
          display:flex; flex-direction:column;
          padding:0;
          border-color:color(blue)!important;

          @include media-breakpoint-up(sm) {
            flex-direction:row;
          }

          .btn {
            position:relative;
            display:flex; justify-content:center; align-items:center;
            width:100%;
            padding:16px;
            font-size:20px; line-height:1.2; font-family:$font_libre_franklin; font-weight:600; color:color(white);
            background-color:color(blue);
            overflow:hidden;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(lg) {
              &:hover {
                &:before {
                  opacity:.15;
                }
              }
            }

            span {
              padding:3px 0;
            }

            &.btn-small {
              font-size:16px;
            }

            i {
              width:20px; height:20px;
              font-size:18px; line-height:20px; text-align:center; color:color(white);
              @include prefix(transition, all .3s ease-out, webkit moz o ms);

              &:first-child {
                margin-right:4px;
              }
              &:last-child {
                margin-left:4px;
              }
            }
            > * {
              position:relative;
              z-index:2;
            }
            &:before {
              content:'';
              position:absolute; left:0; top:0;
              width:100%; height:100%;
              background-color:color(black-000);
              opacity:0;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }
          }
        }

        .form-wrapper {
          padding:0;
          border:0 none;
          background-color:transparent;
          @include prefix(box-shadow, none, webkit moz o ms);

          form {
            border:0 none;
          }

          .form-field {
            padding:0;
            margin-top:16px;

            &:first-child {
              margin-top:0;
            }
          }
        }
      }
    }
    .image {
      float:left;
      width:100%;
      margin-top:0; margin-bottom:-16px;

      @include media-breakpoint-up(lg) {
        margin-bottom:0;
      }

      &.my {
        margin-top:16px; margin-bottom:0;

        @include media-breakpoint-up(lg) {
          margin-top:0;
        }
      }
      &.m-space {
        margin-top:16px; margin-bottom:16px;

        @include media-breakpoint-up(lg) {
          margin-top:0; margin-bottom:0;
        }
      }
      &.brd-round {
        border-radius:4px 4px 16px 4px;
        overflow:hidden;
      }

      .sponsor {
        display:flex; flex-direction:column; align-items:center;
        float:left;
        width:100%;
        margin-top:16px; margin-bottom:16px;

        @include media-breakpoint-up(lg) {
          align-items:flex-start;
          margin-bottom:0;
        }

        p {
          position:relative;
          width:auto;
          padding:4px 0 4px 40px;
          font-size:10px; line-height:1.6; font-family:$font_libre_franklin; font-weight:400; letter-spacing:0.6px;

          @include media-breakpoint-up(xs-375) {
            font-size:calc(10px + 0 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            font-size:10px;
          }

          &:before {
            content:'';
            position:absolute; left:11px; top:12px;
            width:18px; height:1px;
            border-radius:2px;
            background-color:color(black-400);
          }
        }
        ul {
          display:flex; flex-direction:row; justify-content:center; flex-wrap:wrap;
          width:100%;
          margin-top:-12px;

          @include media-breakpoint-up(lg) {
            justify-content:flex-start;
            width:calc(100% + 16px);
            margin-right:-16px;
          }

          li {
            width:auto;
            margin-top:12px; margin-right:8px; margin-left:8px;

            @include media-breakpoint-up(lg) {
              margin-right:16px; margin-left:0;
            }

            picture {
              width:96px;
              border-radius:4px 4px 16px 4px;
              overflow:hidden;
            }
          }
        }
      }
    }
    .video, .lock {
      position:relative;
      display:block; float:left;
      margin:16px 0 0 0;
      border-radius:4px 4px 16px 4px;
      overflow:hidden;

      @include media-breakpoint-up(lg) {
        margin:0 0 0 20px;
        border-radius:32px 4px;

        &:hover {
          &:before {
            opacity:0.9;
          }
        }
      }

      &:before {
        content:"\e3d2";
        position:absolute; left:50%; top:50%;
        display:block;
        width:68px; height:68px;
        font-size:68px; line-height:68px; text-align:center; color:color(white);
        opacity:0.65;
        font-family:"Phosphor"!important; speak:never; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none;
        letter-spacing:0; -webkit-font-feature-settings:"liga"; -moz-font-feature-settings:"liga=1"; -moz-font-feature-settings:"liga"; -ms-font-feature-settings:"liga" 1; font-feature-settings:"liga"; -webkit-font-variant-ligatures:discretionary-ligatures; font-variant-ligatures:discretionary-ligatures;
        -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;
        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
        z-index:2;

        @include media-breakpoint-up(xs-375) {
          width:calc(68px + 40 * ((100vw - 375px) / 1065)); height:calc(68px + 40 * ((100vw - 375px) / 1065));
          font-size:calc(68px + 40 * ((100vw - 375px) / 1065)); line-height:calc(68px + 40 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          width:108px; height:108px;
          font-size:108px; line-height:108px;
        }
      }
      picture {
        position:relative;
        z-index:1;
        
        &:before {
          content:'';
          position:absolute; left:0; top:0;
          width:100%; height:100%;
          background:rgb(0,0,0);
          background:linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
          opacity:.7;
          z-index:1;
        }
      }
    }
    .video {
      &:before {
        content:"\e3d2";
      }
    }
    .lock {
      &:before {
        content:"\e2fe";
      }
    }
  }
  a, button {
    @include media-breakpoint-up(lg) {
      &:hover {
        div {
          &.button {
            .btn {
              &:before {
                opacity:.2;
              }
            }
          }
        }
      }
    }
  }

  &.wide-content, &.extra-content, &.full-content {
    > a, > div, > button {
      min-height:0;

      .content {
        @include media-breakpoint-up(lg) {
          width:47.8%;
        }

        > div {
          &.button {
            border-color:color(black,0.18)!important;

            .btn {
              color:color(black);
              background-color:transparent!important;

              i {
                color:color(black);
              }
            }
          }
        }
      }
    }
  }
  &.full-content {
    > a, > div, > button {
      flex-direction:column;
      align-items:flex-start;

      .content {
        position:relative;
        display:flex; flex-direction:column;
        width:100%!important; height:100%;
        margin:0;
        padding:16px 0;

        > div {
          position:relative;
          z-index:2;

          &.button {
            margin-top:auto;
          }
        }
      }
    }

    &.card-content {
      > a, > div, > button {  
        .content {
          margin-left:0; margin-right:0;
  
          > div {
            border-left:0 none; border-right:0 none;

            &:first-child {
              border-top:0 none;
            }
            &:last-child {
              border-bottom:0 none;
            }
          }
          &:before {
            content:'';
            position:absolute; left:0; top:16px;
            width:100%; height:calc(100% - 32px);
            border:1px solid color(black,.18); border-radius:4px 4px 16px 4px;
            z-index:1;
          }

          &.exhibitor {
            > div {
              &.location {
                padding:16px 8px;

                > div {
                  display:flex; flex-wrap:wrap;
                  position:relative;
                  padding-left:48px; margin-bottom:16px;

                  i {
                    position:absolute; left:0; top:0;
                    display:block;
                    width:32px; height:32px;
                    font-size:32px; line-height:32px; text-align:center; color:color(azure-800);
                  }
                  p {
                    margin:0;
                    font-size:16px; line-height:1.5; font-weight:400; font-family:$font_sentient; color:color(black); font-style:italic;
                  }
                  span {
                    position:relative;
                    display:block; float:left;
                    width:auto;
                    padding-right:7px; margin-right:6px;
                    font-size:16px; line-height:1.5; font-weight:700; font-family:$font_sentient; color:color(black);
            
                    &:after {
                      content:'';
                      position:absolute; right:0; top:0;
                      width:1px; height:100%;
                      border-radius:2px;
                      background-color:color(dark-200);
                    }
            
                    &:last-child {
                      padding-right:0; margin-right:0;
            
                      &:after {
                        content:none;
                      }
                    }
                  }
                }                
                .map {
                  position:relative;
                  display:block; float:left;
                  width:auto;
                  padding-left:36px; margin-top:8px;
                  font-size:16px; line-height:1.5; font-weight:400; font-family:$font_libre_franklin; color:color(black-600); text-decoration:underline!important;

                  i {
                    position:absolute; left:0; top:0;
                    display:block;
                    width:24px; height:24px;
                    font-size:24px; line-height:24px; text-align:center; color:color(black-700);
                  }
                }
              }
              &.info {
                ul {
                  margin-top:-8px;

                  @include media-breakpoint-up(xs-375) {
                    margin-top:calc(-8px - 8 * ((100vw - 375px) / 1065));
                  }
                  @include media-breakpoint-up(xl-1440) {
                    margin-top:-16px;
                  }

                  li {
                    position:relative;
                    width:100%;
                    margin:8px 16px 0 0; padding-left:24px;
                    font-size:14px; line-height:1.5; font-weight:600; font-family:$font_libre_franklin; color:color(black);

                    @include media-breakpoint-up(xs-375) {
                      margin:calc(8px + 8 * ((100vw - 375px) / 1065)) calc(16px + 16 * ((100vw - 375px) / 1065)) 0 0;
                    }
                    @include media-breakpoint-up(sm) {
                      width:auto;
                    }
                    @include media-breakpoint-up(xl-1440) {
                      margin:16px 32px 0 0;
                    }

                    &.full {
                      width:100%;
                    }

                    a {
                      text-decoration:underline!important;
                      
                      &.phone {
                        @include media-breakpoint-up(lg) {
                          text-decoration:none!important;
                        }
                      }
                    }
                    i {
                      position:absolute; left:0; top:0;
                      display:block;
                      width:20px; height:20px;
                      font-size:20px; line-height:20px; text-align:center; color:color(black-400);
                    }
                  }
                }                
              }
              &.label {
                p {
                  margin:0;
                  font-size:16px; line-height:1.5; font-weight:400; font-family:$font_sentient; color:color(black); font-style:italic;
                }
                .tag-list {
                  position:relative;
                  padding-left:40px; margin-top:16px;

                  @include media-breakpoint-up(md) {
                    padding-left:48px;
                  }

                  i {
                    position:absolute; left:0; top:0;
                    display:block;
                    width:32px; height:32px;
                    font-size:32px; line-height:32px; text-align:center; color:color(black-400);

                    @include media-breakpoint-up(md) {
                      left:8px;
                    }
                  }
                  ul {
                    li {
                      a, span {
                        padding:2px 8px;
                        font-size:14px; color:color(azure-800); letter-spacing:0;
                      }
                      a {
                        @include media-breakpoint-up(lg) {
                          &:hover {
                            border-color:color(blue)!important;
                          }
                        }
                      }
                    }

                    &.products {
                      li {
                        a, span {
                          border-color:color(black-300);
                          color:color(black-900);
                        }
                      }
                    }
                  }
                }
              }
              &.description {
                p {
                  font-size:20px;
                }
              }
              &.staff {
                ul {
                  margin-top:-8px;

                  li {
                    width:auto;
                    margin:8px 8px 0 0;
                    font-size:16px; line-height:1.5; font-weight:400; font-family:$font_sentient; color:color(black); font-style:italic;

                    strong {
                      padding-left:8px;
                      font-weight:700; font-style:normal; letter-spacing:0.32px;

                      @include media-breakpoint-down(sm) {
                        display:inline-block; float:left;
                        width:100%;
                        padding-left:0;
                      }
                    }
                    
                    @include media-breakpoint-up(sm) {
                      &:after {
                        content:',';
                        font-style:normal;
                      }
  
                      &:last-child {
                        &:after {
                          content:'.';
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.p-base {
    > a, > div, > button {
      padding:16px;
    }
  }
  &.p-small {
    > a, > div, > button {
      padding:0 16px;
    }
  }
  &.square {
    border-radius:0!important;
  }

  &.right {
    > a, > div, > button {
      flex-direction:column;

      @include media-breakpoint-up(lg) {
        flex-direction:row;
      }

      .content {
        @include media-breakpoint-up(lg) {
          float:right;
          margin:16px 0 16px 16px;
        }
      }
      .image {
        @include media-breakpoint-up(lg) {
          float:right;
        }
      }
    }
  }

  &.card-content {
    > a, > div, > button {
      padding:0 16px;

      @include media-breakpoint-up(lg) {
        min-height:0;
      }

      .content {
        width:100%;

        @include media-breakpoint-up(lg) {
          width:48.5%;
        }
      }
    }

    &.wide-content, &.full-content {
      > a, > div, > button {
        .content {
          @include media-breakpoint-up(lg) {
            width:54%;
          }

          > div {
            p {
              font-size:14px;
            }
          }
        }
      }
    }
    &.extra-content {
      > a, > div, > button {
        .content {
          @include media-breakpoint-up(lg) {
            width:59.25%;
          }
        }
      }
    }
  }

  &.icon-left, &.icon-right, &.icon-top {
    > a, > div, > button {
      flex-direction:column; align-items:flex-start;
      min-height:0;
      padding:0 16px;

      @include media-breakpoint-up(lg) {
        flex-direction:row;
      }

      .icon {
        position:relative;
        width:72px; height:72px;
        margin-top:16px;
        flex-shrink:0;

        i {
          position:absolute; left:0; top:0;
          width:72px; height:72px;
          font-size:60px; line-height:72px; text-align:center; color:color(black-700);
        }
      }

      .content {
        margin:16px 0;

        > div {
          &.button {
            border-color:color(black,0.18)!important;

            .btn {
              color:color(black);
              background-color:transparent!important;

              i {
                color:color(black);
              }
            }
          }
        }
      }
    }
  }
  &.icon-left {
    > a, > div, > button {
      .icon {
        margin-right:16px;
      }
      .content {
        @include media-breakpoint-up(lg) {
          width:calc(100% - 88px);
        }
      }
    }
  }
  &.icon-right {
    > a, > div, > button {
      @include media-breakpoint-up(lg) {
        flex-direction:row-reverse;
      }
      .icon {
        margin-left:16px;
      }
      .content {
        @include media-breakpoint-up(lg) {
          width:calc(100% - 88px);
        }
      }
    }
  }
  &.icon-top {
    > a, > div, > button {
      @include media-breakpoint-up(lg) {
        flex-direction:column;
      }
      .content {
        width:100%;
      }
    }
  }

  &.card-list {
    > a, > div, > button {
      flex-direction:column;
      padding:0 0 16px 0;

      @include media-breakpoint-up(lg) {
        min-height:490px;
      }

      .content {
        width:100%;
        margin:0;

        > div {
          padding:32px 16px 0 16px;
          border:0 none; border-radius:0!important;

          @include media-breakpoint-up(lg) {
            padding:16px 16px 0 16px;
          }
        
          &:has(> .pretitle) {
            padding:16px 16px 0 16px;
          }

          p {
            font-size:14px;
          }

          &.button {
            padding:0;

            .btn {
              justify-content:flex-end;
              padding:16px 32px;
              color:color(black)!important;
              background-color:transparent!important;

              i {
                color:color(black)!important;
              }
              &:before {
                content:none;
              }
              @include media-breakpoint-up(lg) {
                &:hover {
                  color:color(blue-600)!important;

                  i {
                    color:color(blue-600)!important;
                  }
                }
              }
            }
          }
        }
      }
    }
    > a, > button {
      @include media-breakpoint-up(lg) {
        &:hover {
          .content {
            > div {
              &.button {
                .btn {
                  color:color(blue-600)!important;

                  i {
                    color:color(blue-600)!important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.card-small {
    > a, > div, > button {
      flex-direction:row;
      min-height:0;
      padding:0 16px;

      .image {
        width:96px;
        margin-right:16px; margin-top:16px; margin-bottom:16px;
        border-radius:4px 4px 16px 4px;
        flex-shrink:0;
        overflow:hidden;
      }
      .content {
        width:calc(100% - 112px);

        > div {
          padding:0; border:0 none;

          &.button {
            margin-top:8px;

            .btn {
              justify-content:flex-start;
              padding:0;
              font-size:14px; color:color(black)!important;
              background-color:transparent!important;

              i {
                width:14px; height:14px;
                margin-right:5px;
                font-size:12px; line-height:14px; color:color(black)!important;
              }
              &:before {
                content:none;
              }
            }
          }
        }
      }
    }

    &:not([class*="bkg-"]) {
      > a, > div, > button {
        .content {  
          > div {  
            &.button {  
              .btn {
                @include media-breakpoint-up(lg) {
                  &:hover {
                    color:color(blue-600)!important;
  
                    i {
                      color:color(blue-600)!important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      > a, > button {
        @include media-breakpoint-up(lg) {
          &:hover {
            .content {
              > div {
                &.button {
                  .btn {
                    color:color(blue-600)!important;
  
                    i {
                      color:color(blue-600)!important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.card-news {
    background-color:color(grey-700);
    @include prefix(box-shadow, none, webkit moz o ms);

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      border:1px solid color(black-300);
      border-radius:4px 4px 16px 4px;
      z-index:1;

      @include media-breakpoint-up(lg) {
        border-radius:8px 8px 32px 8px;
      }
    }
    > a, > div, > button {
      position:relative;
      min-height:0;
      padding:0 8px;
      z-index:2;

      .content {
        width:100%;
        margin:8px 0 0 0;

        > div {
          padding:8px 0 16px 0;
          border-radius:0;
          border:0 none; border-top:1px solid color(grey-300);

          &:first-child {
            border-top:0 none;
          }

          &.datetime {
            display:flex; justify-content:space-between; align-items:flex-start;

            time {
              display:flex; flex-direction:column; align-items:center;
              width:auto; min-width:64px;
              margin-right:16px; padding:2px 6px;
              border-radius:4px 4px 16px 4px;
              border:1px solid color(grey-300);
              box-sizing:border-box;
              font-size:16px; line-height:1.2; font-weight:500; font-family:$font_libre_franklin; color:color(black-900); text-align:center; text-transform:uppercase;

              strong {
                width:calc(100% + 12px);
                padding-top:2px;
                margin:2px -6px 0 -6px;
                border-top:1px solid color(grey-300);
                font-size:28px; font-weight:700;
              }
            }
            > div {
              display:flex; flex-direction:column; align-items:flex-start;
              padding-left:20px;

              p {
                width:auto;
                margin-top:0;

                &.label {
                  position:relative;
                  min-height:20px;
                  padding:4px 16px 4px 20px;
                  border-radius:0 4px 4px 0;
                  background-color:color(blue-200);
                  font-size:9px; line-height:1.33; font-weight:400; font-family:$font_libre_franklin; color:color(black-900); letter-spacing:0.9px; text-transform:uppercase;

                  i {
                    position:absolute; left:0; top:4px;
                    width:12px; height:12px;
                    font-size:12px; line-height:12px; text-align:center; color:color(blue);
                  }
                  &:before {
                    content:'';
                    position:absolute; top:0; left:-20px;
                    width:0; height:0;
                    border-style:solid;
                    border-width:0 20px 20px 0;
                    border-color:transparent color(blue-200) transparent transparent;
                  }
                }
                &.hour {
                  position:relative;
                  margin-top:16px; padding-left:20px;
                  font-size:16px; line-height:1.25; font-weight:500; font-family:$font_libre_franklin; color:color(black-900);

                  i {
                    position:absolute; left:0; top:2px;
                    width:16px; height:16px;
                    font-size:13px; line-height:16px; text-align:center; color:color(black-300);
                  }
                }
              }
            }
          }
          &.intro {
            h3, .h3 {
              font-size:27px; line-height:1.1; font-weight:500; font-style:italic; font-family:$font_cormorant; color:color(black-500);
            }
            p {
              margin-top:8px;
              font-size:14px;
            }
          }
          &.location {
            position:relative;
            padding-left:24px;

            > i {
              position:absolute; left:0; top:8px;
              width:16px; height:16px;
              font-size:14px; line-height:16px; text-align:center; color:color(black-300);
            }
            p {
              font-size:16px; line-height:1.2; font-weight:500; font-family:$font_libre_franklin; color:color(black-500);
            }
            ul {
              margin-top:-8px;

              li {
                width:auto;
                margin-top:8px; margin-right:4px; padding-right:4px;
                border-right:1px solid color(grey-300);
                font-size:14px; line-height:1.2; font-weight:500; font-family:$font_libre_franklin; color:color(black-300);

                &:last-child {
                  margin-right:0; padding-right:0; border-right:0 none;
                }

                i {
                  display:inline-block;
                  width:16px; height:16px;
                  margin-left:3px;
                  font-size:13px; line-height:16px; text-align:center; color:color(black-300);
                }
              }
            }
          }
          &.detail {
            p {
              position:relative;
              margin-top:4px;
              padding-left:20px; padding-right:8px;
              font-size:14px; line-height:1.5; font-weight:400; font-family:$font_sentient; color:color(black-900); font-style:italic;

              strong {
                font-weight:400; font-style:normal;
              }
              i {
                position:absolute; left:0; top:0;
                width:16px; height:16px;
                font-size:15px; line-height:16px; text-align:center; color:color(black-300);
              }

              &:first-child {
                margin-top:0;
              }
            }
          }
        }
      }
    }
  }

  &.card-event, &.card-exhibitor {
    background-color:color(white-400);
    @include prefix(box-shadow, none, webkit moz o ms);

    &.open {
      height:auto!important;
    }

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      border:1px solid color(grey-600);
      border-radius:4px 4px 16px 4px;
      z-index:1;

      @include media-breakpoint-up(lg) {
        border-radius:8px 8px 32px 8px;
      }
    }
    > a, > div, > button {
      position:relative;
      align-items:flex-start;
      min-height:0;
      padding:0 16px;
      z-index:2;

      .content {
        display:flex; flex-direction:column;
        width:100%; height:100%;
        margin:0; padding:8px 0;

        > div {
          padding:8px 0;
          border-radius:0;
          border:0 none; border-top:0 none; border-bottom:1px solid color(grey-600);
          font-size:0;

          &:last-child {
            border-bottom:0 none;
          }

          .detail, .note {
            float:left;
            width:100%;
            margin-top:8px;

            p {
              position:relative;
              margin-top:4px;
              padding-left:20px;
              font-size:14px; line-height:1.5; font-weight:400; font-family:$font_sentient; color:color(black-800); font-style:italic;

              strong {
                font-weight:400; font-style:normal;
              }
              i {
                position:absolute; left:0; top:2px;
                width:16px; height:16px;
                font-size:15px; line-height:16px; text-align:center; color:color(black-700);
              }

              &:first-child {
                margin-top:0;
              }
            }
          }

          &.intro {
            .label {
              margin-top:-4px; margin-bottom:8px;

              li {
                position:relative;
                width:auto;
                margin:4px 4px 0 0;
                padding:3px 8px 1px 8px;
                border:1px solid color(black-300);
                border-radius:4px 4px 8px 4px;
                font-size:10px; line-height:15px; color:color(grey-500); font-family:$font_libre_franklin; font-weight:700; white-space:nowrap; letter-spacing:0.6px; text-transform:uppercase;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
    
                i {
                  position:absolute; left:8px; top:50%;
                  width:12px; height:12px;
                  font-size:11px; line-height:12px; text-align:center; color:color(grey-500);
                  @include prefix(transform, translateY(-50%), webkit moz o ms);
                }

                &:has(> i) {
                  padding:3px 8px 1px 24px;
                }

                &.main {
                  border-color:color(azure-200);
                  color:color(azure-800); font-weight:400;

                  i {
                    color:color(azure-800);
                  }
                }
              }
            }
            h3, .h3 {
              position:relative;
              font-size:22px; line-height:1.1; color:color(black-800); font-family:$font_cormorant; font-weight:500; font-style:italic;

              &:has(> i) {
                min-height:32px;
                padding-left:40px;
              }
              i {
                position:absolute; left:0; top:0;
                width:32px; height:32px;
                border-radius:4px;
                font-size:18px; line-height:32px; text-align:center; color:color(black-700);
                background-color:color(green-100);
              }
            }
            p {
              margin-top:8px;
              font-size:14px;
            }
          }
          &.info {
            ul {
              li {
                position:relative;
                margin-top:4px;
                padding-left:20px;
                font-size:14px; line-height:1.5; font-weight:600; font-family:$font_libre_franklin; color:color(black-700);

                i {
                  position:absolute; left:0; top:2px;
                  width:16px; height:16px;
                  font-size:15px; line-height:16px; text-align:center; color:color(black-700);
                }

                span {
                  position:relative;
                  padding-right:7px; margin-right:6px;

                  &:after {
                    content:'';
                    position:absolute; right:0; top:0;
                    width:1px; height:100%;
                    border-radius:2px;
                    background-color:color(dark-200);
                  }

                  &:last-child {
                    padding-right:0; margin-right:0;

                    &:after {
                      content:none;
                    }
                  }
                }

                &:first-child {
                  margin-top:0;
                }
              }
            }
          }
          &.availability {
            display:flex; flex-wrap:wrap; align-items:center;

            span {
              position:relative;
              display:block; float:left;
              width:auto; max-width:calc(100% - 24px);
              padding:2px 8px 2px 24px;
              border:1px solid color(azure);
              border-radius:4px 4px 8px 4px;
              font-size:14px; line-height:17px; color:color(black); font-family:$font_libre_franklin; font-weight:400;
              background-color:color(azure);
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
  
              &:before {
                content:"\e012";
                position:absolute; left:8px; top:50%;
                display:block;
                width:12px; height:12px;
                font-size:11px; line-height:12px; text-align:center; color:color(black-900);
                font-family:"Phosphor"!important; speak:never; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none;
                letter-spacing:0; -webkit-font-feature-settings:"liga"; -moz-font-feature-settings:"liga=1"; -moz-font-feature-settings:"liga"; -ms-font-feature-settings:"liga" 1; font-feature-settings:"liga"; -webkit-font-variant-ligatures:discretionary-ligatures; font-variant-ligatures:discretionary-ligatures;
                -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;
                @include prefix(transform, translateY(-50%), webkit moz o ms);
              }

              &.reservation {
                border-color:color(green);
                background-color:color(green);
              }
              &.date {
                border-color:color(green);
                background-color:transparent;
              }
              &.limited {
                border-color:color(yellow);
                background-color:color(yellow);
              }
              &.sold-out {
                border-color:color(red);
                color:color(white);
                background-color:color(red);
    
                &:before {
                  color:color(white)
                }
              }
              &.closed {
                margin-bottom:4px;
                border-color:color(red-200);
                color:color(red);
                background-color:transparent;

                &:before {
                  content:"\e3de";
                }
              }
            }
            .tooltip-btn {
              position:relative;
              width:20px; height:20px;
              padding:0; border:0 none;
              margin-left:4px;
              background-color:transparent;

              i {
                position:absolute; left:0; top:0;
                width:20px; height:20px;
                font-size:17px; line-height:20px; text-align:center; color:color(black);
              }
            }
            hr {
              display:block; float:left;
              width:100%; height:0;
              margin:0; padding:0; border:0 none;
              flex-shrink:0;
            }
          }
          &.insight {
            .accordion-item {
              margin:0; padding:0; border-radius:0;
              background-color:transparent;
              @include prefix(box-shadow, none, webkit moz o ms);

              &:before {
                content:none;
              }
              .accordion-header {
                margin-top:2px;

                .accordion-button {
                  padding:0 0 0 20px;
                  font-size:10px; line-height:16px; letter-spacing:0.6px; color:color(black-800)!important; font-weight:400; text-decoration:underline; font-family:$font_libre_franklin;

                  &:before {
                    content:"\e222";
                    position:absolute; left:0; top:0;
                    display:block;
                    width:16px; height:16px;
                    font-size:14px; line-height:16px; text-align:center; color:color(dark);
                    font-family:"Phosphor"!important;
                    @include prefix(transform, translateY(0%), webkit moz o ms);
                  }

                  &[aria-expanded="true"] {
                    margin-top:8px;

                    &:before {
                      content:"\e220";
                    }
                  }
                }
              }
              .accordion-collapse {
                padding:0;

                .accordion-body {
                  > div {
                    float:left;
                    width:100%;
                    padding:8px 0;
                    border-radius:0;
                    border:0 none; border-top:0 none; border-bottom:1px solid color(grey-600);
                    font-size:0;
  
                    &.partner {
                      p {
                        position:relative;
                        padding:0 0 0 40px;
                        font-size:10px; line-height:1.5; color:color(black-800); font-weight:400; font-family:$font_libre_franklin; letter-spacing:0.6px;
                    
                        &:before {
                          content:'';
                          position:absolute; left:11px; top:6px;
                          width:18px; height:2px;
                          border-radius:2px;
                          background-color:color(black-800);
                        }
                      }
                      .partner-list {
                        display:flex; flex-direction:row; justify-content:flex-start; flex-wrap:wrap;
                        float:none;
                        width:100%;
                        margin-top:-4px; margin-bottom:4px;
                    
                        li {
                          width:auto;
                          padding:0;
                          margin-top:10px; margin-right:20px;
                    
                          &:before {
                            content:none;
                          }
                    
                          picture {
                            height:28px; width:auto;
                            margin:0;
                            overflow:hidden;

                            img {
                              height:100%; width:auto;
                            }
                          }
                        }
                      }
                    }
                    &.tag-list {
                      margin:0;

                      p {
                        color:color(black-700);
                      }
                    }
                    &.detail {
                      margin-top:0;
                    }
                    &.note {
                      margin-top:0;

                      p {
                        color:color(black-600); font-family:$font_libre_franklin; font-style:normal;
                      }
                    }
                  }
                }
              }
            }
          }
          &.bottom {
            display:flex; justify-content:space-between; align-items:center;
            margin-top:auto; padding-top:16px;

            .fav {
              position:relative;
              display:flex; justify-content:center; align-items:center; flex-shrink:0;
              width:38px; height:38px;
              border-radius:50%;
              border:1px solid color(fuchsia);
              background-color:transparent;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
              z-index:1;
          
              i {
                position:absolute; left:50%; top:50%;
                width:18px; height:18px;
                font-size:18px; line-height:18px; text-align:center; color:color(fuchsia);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
              }
          
              @include media-breakpoint-up(lg) {
                &:hover {
                  background-color:color(fuchsia);
          
                  i {
                    color:color(fuchsia-900);
                  }
                }
              }
            }

            .btn-base, .btn-link {
              padding-left:12px; padding-right:12px;
              font-size:16px;
            }
            .btn-base {
              border-radius:4px 4px 16px 4px;

              &:before {
                border-radius:4px 4px 16px 4px;
              }
            }
          }
        }
      }
    }
  }

  &.card-exhibitor {
    > a, > div, > button {
      .content {
        > div {
          &.intro {
            .location {
              position:relative;
              margin-top:4px;
              padding-left:28px;

              li {
                position:relative;
                width:auto;
                margin:4px 6px 0 0; padding-right:7px;
                font-size:16px; line-height:1.5; color:color(black-800); font-family:$font_sentient; font-weight:400; text-align:center;

                strong {
                  font-weight:700;
                }
                &:after {
                  content:'';
                  position:absolute; right:0; top:50%;
                  width:1.5px; height:15px;
                  background-color:color(black-800);
                  @include prefix(transform, translateY(-50%), webkit moz o ms);
                }
                &:last-child {
                  padding-right:0; margin-right:0;

                  &:after {
                    content:none;
                  }
                }
              }
              &:before {
                content:"\e470";
                position:absolute; left:0; top:4px;
                display:block;
                width:24px; height:24px;
                font-size:20px; line-height:24px; text-align:center; color:color(black);
                font-family:"Phosphor"!important; speak:never; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none;
                letter-spacing:0; -webkit-font-feature-settings:"liga"; -moz-font-feature-settings:"liga=1"; -moz-font-feature-settings:"liga"; -ms-font-feature-settings:"liga" 1; font-feature-settings:"liga"; -webkit-font-variant-ligatures:discretionary-ligatures; font-variant-ligatures:discretionary-ligatures;
                -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;
              }
            }
            .label {
              margin-top:12px;

              li {
                border:1px solid color(azure-200);
                color:color(azure-800);
    
                i {
                  color:color(azure-800);
                }
              }
            }
          }
          .map {
            position:relative;
            display:block; float:left;
            width:auto;
            padding-left:28px;
            font-size:16px; line-height:1.5; font-weight:400; font-family:$font_libre_franklin; color:color(black-600); text-decoration:underline!important;

            i {
              position:absolute; left:0; top:0;
              display:block;
              width:24px; height:24px;
              font-size:21px; line-height:24px; text-align:center; color:color(black-700);
            }
          }
        }
      }
    }
  }

  &.card-sponsor {
    border:2px solid color(grey-600);
    background-color:color(grey-800);
    @include prefix(box-shadow, none, webkit moz o ms);

    > a, > div, > button {
      position:relative;
      align-items:flex-start; flex-direction:column;
      min-height:0;
      padding:16px;
      z-index:2;

      .image {
        position:relative;
        float:left;
        width:100%;
        margin:0;
        border-radius:4px 4px 16px 4px;
        overflow:hidden;

        span.logo {
          position:absolute; left:0; bottom:0;
          width:auto;
          z-index:1;

          &.h32 {
            height:32px;
          }
          &.h64 {
            height:64px;
          }

          img {
            height:100%; width:auto;
          }
        }
        picture {
          &.logo {
            width:auto;
            
            &.h32 {
              height:32px;
            }
            &.h64 {
              height:64px;
            }

            img {
              height:100%; width:auto;
            }
          }
        }
      }
      .content {
        display:block; float:left;
        width:100%;
        margin:8px 0 24px 0; padding:0;
        
        p {
          margin-top:8px;
          font-size:14px;
        }
      }
      .btn-base, .btn-link {
        padding-left:12px; padding-right:12px;
        font-size:16px;
      }
      .btn-base {
        margin-left:auto; margin-top:auto;
        border-radius:4px 4px 16px 4px;

        &:before {
          border-radius:4px 4px 16px 4px;
        }
      }
    }

    &.banner {
      border:0 none;

      > a, > div, > button {
        padding-top:8px;
        align-items:center;

        p {
          margin-bottom:12px;
          font-size:10px; line-height:1.5; letter-spacing:0.6px; font-family:$font_libre_franklin; font-weight:600; font-style:normal; text-align:center;
        }
      }
    }
  }

  &.card-media {
    > a, > div, > button {
      position:relative;
      align-items:flex-start; flex-direction:column;
      min-height:0;
      padding:0;
      z-index:2;

      .image {
        position:relative;
        margin:0;

        &.video, &.lock {
          margin:0; border-radius:0;

          &:before {
            width:60px; height:60px;
            font-size:60px; line-height:60px;
            font-family:"Phosphor-fill"!important;
            opacity:.9;
            @include prefix(filter, drop-shadow(0px 0px 16px rgba(0,0,0,0.15)), webkit moz o ms);
            z-index:2;
    
            @include media-breakpoint-up(xs-375) {
              width:calc(60px + 0 * ((100vw - 375px) / 1065)); height:calc(60px + 0 * ((100vw - 375px) / 1065));
              font-size:calc(60px + 0 * ((100vw - 375px) / 1065)); line-height:calc(60px + 0 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              width:60px; height:60px;
              font-size:60px; line-height:60px;
            }
          }
          &:after {
            content:'';
            position:absolute; left:50%; top:50%;
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            background-position:center; background-repeat:no-repeat; background-size:contain;
            z-index:3;
          }

          picture {
            &:before {
              content:none;
            }
          }
        }
        &.video {
          &:after {
            width:18px; height:23px;
            margin-left:3px;
            background-image:url(../img/icon-video-fill.svg);           
          }
        }
        &.lock {
          &:after {
            width:12px; height:20px;
            margin-top:5px;
            background-image:url(../img/icon-lock-fill.svg);    
          }
        }
      }
      .content {
        display:flex; flex-direction:column; flex:1;
        width:100%; height:100%;
        margin:0; padding:0 0 32px 0;
        border:0 none; border-radius:0;

        > div {
          padding:16px 16px 0 16px;
          border:0 none; border-radius:0;

          .badge {
            position:relative;
            display:block; float:left;
            width:auto;
            padding:4px 8px 4px 28px; border-radius:2px 2px 8px 2px;
            margin-bottom:16px; margin-left:4px;
            font-size:16px; line-height:1.5; color:color(blue-800); font-family:$font_libre_franklin; font-weight:400;

            &:first-child {
              margin-left:0;
            }

            i {
              position:absolute; left:8px; top:8px;
              width:16px; height:16px;
              font-size:14px; line-height:16px; color:color(blue-800); text-align:center;
            }
            &:before {
              content:'';
              position:absolute; left:0; top:0;
              width:100%; height:100%;
              border-radius:2px 2px 8px 2px;
              border:1px solid color(blue,.6);
            }

            &[class*=" "] {
              color:color(black)!important;

              i {
                color:color(black)!important;
              }
              &:before {
                content:none;
              }
            }
          }

          &.button {
            margin-top:auto;
            padding-right:32px;

            .btn {
              width:auto;
              padding:0; margin-left:auto;
              font-size:16px; line-height:1.5; color:color(black);
              background-color:transparent;

              i {
                color:color(black);
              }
              &:before {
                content:none;
              }
            }
          }
        }
      }
    }
    > a, > button {
      @include media-breakpoint-up(lg) {
        &:hover {
          .image {    
            &.video, &.lock {    
              &:before {
                opacity:1;
              }
            }
          }
          .content {    
            > div {    
              &.button {    
                .btn {
                  color:color(blue);
                }
              }
            }
          }
        }
      }
    }

    &.alt {
      @include media-breakpoint-up(md) {
        border-radius:4px 4px 16px 4px;

        > a, > div, > button {
          flex-direction:row;
          padding:16px;

          &:before {
            content:'';
            position:absolute; left:8px; top:8px;
            width:calc(100% - 16px); height:calc(100% - 16px);
            border-radius:2px 2px 8px 2px; border:1px solid color(black,0.18);
          }
    
          .image {
            width:auto;

            picture {
              height:calc(90px + 100 * ((100vw - 768px) / 672)); width:auto;
              border-radius:4px; border:1px solid color(grey-600);

              img {
                height:100%; width:auto;
              }
            }
          }
          .content {
            max-width:142px;
            padding:0 0 0 16px;
    
            > div {
              padding:0 0 8px 0;

              h5, .h5 {
                font-size:14px; line-height:1.5; font-weight:600;
              }
              h6, .h6 {
                margin-top:8px;
                font-size:10px; line-height:1.5; font-weight:700; font-family:$font_sentient;
              }
              p {
                font-size:10px;
              }
    
              &.button {
                padding-right:8px;
    
                .btn {
                  font-size:10px; letter-spacing:0.6px;
                }
              }
            }
          }
        }
      }
      @include media-breakpoint-up(xl-1440) {
        > a, > div, > button {    
          .image {
            picture {
              height:190px; width:auto;
            }
          }
        }
      }
    }
  }

  &.card-number {
    border-radius:4px 4px 16px 4px;
    @include prefix(box-shadow, 0px 12px 8px 0px rgba(32,32,32,0.05), webkit moz o ms);

    > a, > div, > button {
      display:flex; justify-content:center; align-items:center; flex-direction:row;
      min-height:0;
      padding:16px 32px;

      i {
        width:72px; height:72px;
        margin-right:16px;
        font-size:63px; line-height:72px; text-align:center; color:color(black);
      }
      p {
        display:flex; flex-direction:column;
        max-width:180px;
        font-size:20px; line-height:1.2; font-weight:500; font-family:$font_libre_franklin; color:color(black); text-align:left;

        strong {
          font-size:40px; font-weight:700;
        }
      }
    }

    &.vert {
      > a, > div, > button {
        flex-direction:row;

        @include media-breakpoint-up(sm) {
          flex-direction:column;
        }

        i {
          @include media-breakpoint-up(sm) {
            margin-right:0; margin-bottom:16px;
          }

          & + p {
            text-align:left;

            @include media-breakpoint-up(sm) {
              text-align:center;
            }
          }
        }
        p {
          text-align:center;
        }
      }
    }
  }
}

section {
  &.p-0 {
    .card {
      width:calc(100% + 16px);
      margin-left:-8px; margin-right:-8px;
      border-radius:0;

      @include media-breakpoint-up(xl) {
        width:calc(100% + 8px);
        margin-left:0; margin-right:-8px;
      }
    }
  }
}
