.slider-gallery-wrapper {
  float:left;
  width:100%;
  
  .swiper-slide {
    figure {
      position:relative;

      figcaption {
        position:absolute; left:0; bottom:46px;
        width:100%;
        padding:0 17%;
        font-size:14px; line-height:1.5; color:color(white); font-family:$font_sentient; font-weight:400; text-align:center;
        z-index:2;

        h5, .h5 {
          margin-bottom:8px;
          font-family:$font_libre_franklin; color:color(white);
        }
      }
      &:before {
        content:'';
        position:absolute; left:0; bottom:0;
        width:100%; height:40%;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        opacity:.8;
        z-index:1;
      }
    }
  }

  .swiper-button-prev, .swiper-button-next {
    position:absolute; top:0;
    width:15%; height:100%;
    margin:0;
    opacity:.5;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);
    z-index:1;

    &:after {
      content:none;
    }
    i {
      position:absolute; left:50%; top:50%;
      width:32px; height:32px;
      font-size:24px; line-height:32px; text-align:center; color:color(white);
      @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        opacity:1;
      }
    }
  }
  .swiper-button-prev {
    left:0;
  }
  .swiper-button-next {
    right:0;
  }
  .swiper-pagination {
    position:absolute; left:0; bottom:24px;
    width:100%;
    font-size:0;
    z-index:2;

    .swiper-pagination-bullet {
      padding:4px 2px;
      width:34px; height:11px;
      border-radius:0;
      background-color:transparent;
      opacity:.5;

      &:before {
        content:'';
        position:relative;
        display:block; float:left;
        width:30px; height:3px;
        background-color:color(white);
      }

      &.swiper-pagination-bullet-active {
        opacity:1;
      }
    }
  }
}
