.section-intro {
  display:flex; flex-direction:column; align-items:flex-start;
  margin-bottom:8px;

  > div {
    float:left;
    width:100%;
    padding-bottom:16px;
    border-bottom:1px solid color(grey-100);

    > * {
      margin-top:8px!important;

      &:first-child {
        margin-top:0!important;
      }
    }

    &.intro-btn {
      display:flex; flex-direction:row; align-items:center; justify-content:space-between; flex-wrap:wrap;
      margin-top:16px;
      padding-bottom:0; border-bottom:0 none;

      @include media-breakpoint-up(md) {
        justify-content:flex-start;
      }

      .btn {
        margin-top:0!important;

        @include media-breakpoint-down(sm) {
          padding-left:10px; padding-right:10px;
          font-size:16px;
        }
        @include media-breakpoint-up(md) {
          margin-right:16px;
        }
        &:last-child {
          margin-right:0;
        }
      }
      .dropdown-share {
        margin-top:0!important;
      }
    }

    p:not(.subtitle):not(.author) {
      line-height:1.2; font-family:$font_libre_franklin;
    }
    p.author  {
      font-size:14px;
    }
  }
  
  &.mt-med {
    margin-top:4px;
  }

  .pretitle {
    position:relative;
    padding:5px 0 5px 48px;
    font-size:14px; line-height:1.6; font-family:$font_libre_franklin; font-weight:400;

    &:before {
      content:'';
      position:absolute; left:12px; top:50%;
      width:24px; height:2px;
      border-radius:2px;
      background-color:color(dark-300);
    }
  }
  h2, .h2 {
    font-size:32px; font-weight:700;
  }
  p {
    font-weight:500; line-height:1.2;

    &.subtitle {
      width:100%;
      font-size:27px; line-height:1.1; font-family:$font_cormorant; font-style:italic;

      &.med {
        @include media-breakpoint-up(xl) {
          width:90%!important;
        }
      }
      &.short {
        @include media-breakpoint-up(lg) {
          width:90%!important;
        }
        @include media-breakpoint-up(xl) {
          width:60%!important;
        }
      }
    }
    &.author {
      position:relative;
      padding-left:28px;
      font-style:italic;

      i {
        position:absolute; left:0; top:0;
        width:20px; height:20px;
        font-size:20px; line-height:20px; text-align:center; color:color(dark-300);
      }
    }
  }
  .update {
    padding:0;

    span {
      padding:0 0 0 24px;
      border-bottom:0 none;
    }
  }
  .btn {
    margin-top:16px!important;
  }
  .dropdown-share {
    width:auto;
    margin-top:16px;
    padding:0;
    border-bottom:0 none;

    .btn {
      &:after {
        content:none;
      }
      &.show {
        color:color(white)!important;
        background-color:color(blue)!important;

        i {
          color:color(white)!important;
        }
      }
    }
    .dropdown-menu {
      width:100%;
      padding:0; margin:0!important;
      border:0 none;
      background-color:transparent;
      transform:translate3d(0px, 60px, 0px)!important;
      @include prefix(filter, drop-shadow(0px 2px 4px rgba(0,0,0,0.08)), webkit moz o ms);

      &:before {
        content:'';
        position:absolute; left:50%; bottom:100%;
        width:0; height:0;
        margin:0;
        border-style:solid; border-width:0 12.5px 13px 12.5px; border-color:transparent transparent color(white-100) transparent;
        z-index:3;
        @include prefix(transform, translateX(-50%), webkit moz o ms);
      }

      ul {
        display:flex; flex-direction:column;
        width:auto; min-width:328px;
        padding:16px;
        border:0 none; border-radius:8px 8px 32px 8px;
        background-color:color(white-100);

        li {
          width:auto;
          border:1px solid color(black-100,.17); border-bottom:0 none;
          font-size:24px; line-height:1.2; color:color(black); font-family:$font_libre_franklin; font-weight:500; white-space:nowrap;

          &:first-child {
            border-radius:4px 4px 0 0;
          }
          &:last-child {
            border-radius:0 0 16px 4px;
            border-bottom:1px solid color(black-100,.17);
          }

          p, a {
            display:block; float:left;
            width:100%;
            padding:16px;
            font-size:24px; line-height:1.2; color:color(black); font-family:$font_libre_franklin; font-weight:500; white-space:nowrap;
          }
          a {
            position:relative;
            padding-left:80px;
            font-size:20px;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            i {
              position:absolute; left:16px; top:50%;
              width:48px; height:48px;
              font-size:40px; line-height:48px; text-align:center;
              @include prefix(transform, translateY(-50%), webkit moz o ms);
            }
        
            @include media-breakpoint-up(lg) {
              &:hover {
                color:color(blue);
              }
            }
          }
        }
      }
    }
  }
  .info {
    margin-top:4px!important;

    li {
      position:relative;
      width:auto;
      margin-top:4px;
      padding-right:8px;
      font-size:16px; line-height:1.5; font-weight:600; font-family:$font_libre_franklin; color:color(black-700);

      &:has(i) {
        padding-left:20px;
      }

      i {
        position:absolute; left:0; top:4px;
        width:16px; height:16px;
        font-size:16px; line-height:16px; text-align:center; color:color(black-700);
      }

      span {
        position:relative;
        padding-right:7px; margin-right:6px;

        &:after {
          content:'';
          position:absolute; right:0; top:0;
          width:1px; height:100%;
          border-radius:2px;
          background-color:color(dark-200);
        }

        &:last-child {
          padding-right:0; margin-right:0;

          &:after {
            content:none;
          }
        }
      }

      &.full {
        width:100%;
      }
    }
  }

  &.event {
    h1, .h1 {
      line-height:1.1; font-family:$font_cormorant; font-weight:700; font-style:italic;
    }
    .label {
      margin-top:-4px!important;

      li {
        position:relative;
        width:auto;
        margin:4px 4px 0 0;
        padding:3px 8px 1px 24px;
        border:1px solid color(black-300);
        border-radius:4px 4px 8px 4px;
        font-size:10px; line-height:15px; color:color(grey-500); font-family:$font_libre_franklin; font-weight:700; white-space:nowrap; letter-spacing:0.6px; text-transform:uppercase;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        i {
          position:absolute; left:8px; top:50%;
          width:12px; height:12px;
          font-size:12px; line-height:12px; text-align:center; color:color(grey-500);
          @include prefix(transform, translateY(-50%), webkit moz o ms);
        }

        &.main {
          border-color:color(azure-200);
          color:color(blue-800); font-weight:400;

          i {
            color:color(azure-800);
          }
        }
      }
    }
  }
}

.section-head {
  display:block; float:left;
  width:100%;
  margin-top:16px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(16px + 16 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:32px;
  }

  &.mt-small {
    margin-top:16px;
  }

  > *:first-child {
    margin-top:0;
  }

  h2, .h2, h6, .h6 {
    position:relative;
    padding:2px 0 2px 48px;

    &:before {
      content:'';
      position:absolute; left:12px; top:50%;
      width:24px; height:2px;
      border-radius:2px;
      background-color:color(black-400);
      @include prefix(transform, translateY(-50%), webkit moz o ms);
    }
  }
  h6, .h6 {
    padding:4px 0 4px 48px;
  }
  p {
    margin-top:8px;
    font-size:20px;

    &.subtitle {
      font-size:27px; line-height:1.1; font-family:$font_cormorant; font-style:italic; font-weight:500;
    }
  }
}

.bottom-button {
  display:flex; justify-content:center;
  width:100%;
  text-align:center; font-size:0;
  margin-top:0;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(0px + 16 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:16px;
  }

  .btn {
    margin-left:8px; margin-right:8px; margin-top:16px;
  }
}

section {
  &.p-0 {
    .card {
      width:calc(100% + 16px);
      margin-left:-8px; margin-right:-8px;

      @include media-breakpoint-up(xl) {
        width:calc(100% + 8px);
        margin-left:0; margin-right:-8px;
      }
    }
    .slider-gallery-wrapper {
      width:calc(100% + 16px);
      margin-left:-8px; margin-right:-8px;

      @include media-breakpoint-up(xl) {
        width:calc(100% + 8px);
        margin-left:0; margin-right:-8px;
      }
    }
  }
}

.col-main, .col-full {
  flex:0 0 auto;
  width:100%;

  @include media-breakpoint-up(lg) {
    width:70%;
  }
  @include media-breakpoint-up(xl) {
    width:60%;
  }

  .section-head {
    margin-top:16px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(16px + 12 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:28px;
    }
  }
  > article {
    float:left;

    &.card {
      height:auto;
      margin-top:8px;
    }
    &.text-article {
      padding-top:8px;

      &.mt-more {
        margin-top:32px!important;
      }
    }
  }

  > .breadcrumb, > section {
    padding-left:0; padding-right:0;
  }
  > section {
    &.p-0 {
      .card {
        @include media-breakpoint-up(xl) {
          width:calc(100% + 24px);
          margin-left:-16px; margin-right:-8px;
        }
      }
      .slider-gallery-wrapper {
        @include media-breakpoint-up(xl) {
          width:calc(100% + 24px);
          margin-left:-16px; margin-right:-8px;
        }
      }
    }
  }
}
.col-full {
  flex:0 0 auto;
  width:100%;
}
.col-side {
  flex:0 0 auto;
  width:100%;

  @include media-breakpoint-up(lg) {
    width:30%;
  }
  @include media-breakpoint-up(xl) {
    margin-left:10%;
  }

  .side-head {
    position:relative;
    display:block; float:left;
    width:100%;
    margin-bottom:0; margin-top:24px;
    padding-left:48px;

    @include media-breakpoint-up(xs-375) {
      margin-bottom:calc(0px + 16 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-bottom:16px;
    }

    i {
      position:absolute; left:8px; top:0;
      width:32px; height:32px;
      font-size:24px; line-height:32px; text-align:center; color:color(black-400);
    }
    p {
      padding:4px 0;
      font-weight:400; font-family:$font_libre_franklin; color:color(black); text-align:left;
    }

    h6, .h6 {
      padding:5px 0;
      line-height:22px;

      strong {
        font-weight:700;
      }
    }
  }
  .card {
    float:left;
    margin-top:16px;
  }
}

.col-large {
  .col-main, .col-full {
    @include media-breakpoint-up(xl) {
      width:68.5%;
    }
  }
  .col-side {
    @include media-breakpoint-up(xl) {
      margin-left:1.5%;
    }
  }
}