$icomoon-font-family:"icomoon" !default;
$icomoon-font-path:"../fonts" !default;

@font-face {
  font-family:'#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/icomoon.ttf?56cu01') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?56cu01') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?56cu01##{$icomoon-font-family}') format('svg');
  font-weight:normal;
  font-style:normal;
  font-display:block;
  font-display:swap;
}

[class^="icon-salone-"], [class*=" icon-salone-"] {
  font-family:'icomoon' !important;
  speak:never;
  font-style:normal;
  font-weight:normal;
  font-variant:normal;
  text-transform:none;
  line-height:1;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}
