.sidebar {
  position:fixed; left:0; bottom:0;
  display:flex; flex-direction:column; align-items:flex-start; justify-content:space-between;
  width:100%; height:auto;
  background-color:color(white-100);
  z-index:80;

  @include media-breakpoint-down(xl) {
    height:auto!important;
  }
  @include media-breakpoint-up(xl) {
    left:50%;
    width:calc(208px + 40 * ((100vw - 1200px) / 240)); height:calc(100vh - 73px);
    padding:0 0 16px 32px;
    @include prefix(transform, translateX(calc(-600px - 120 * ((100vw - 1200px) / 240))), webkit moz o ms);
    overflow:auto;
  }
  @include media-breakpoint-up(xl-1440) {
    width:248px;
    @include prefix(transform, translateX(-720px), webkit moz o ms);
  }

  &:after {
    content:'';
    position:absolute; right:0; top:0;
    width:100%; height:1px;
    background-color:color(grey-100);
    z-index:2;

    @include media-breakpoint-up(xl) {
      right:0; bottom:0; top:inherit;
      width:1px; height:100%;
    }
  }

  .side-menu {
    position:relative;
    display:block; float:left;
    width:100%;
    z-index:1;

    @include media-breakpoint-up(xl) {
      padding-top:5px;
    }

    > button {
      width:calc(100% - 16px);
      margin:8px;

      @include media-breakpoint-up(lg) {
        display:none;
      }
    }

    ul {
      display:flex; flex-direction:row; justify-content:space-around; align-items:flex-start;
      border-top:1px solid color(grey-100);

      @include media-breakpoint-up(xl) {
        flex-direction:column; justify-content:flex-start;
      }

      &:first-child {
        border-top:0 none;
      }
    }

    li {
      display:none;
      padding:0;

      @include media-breakpoint-up(xl) {
        display:block!important;
        border-bottom:1px solid color(grey-100);
      }

      a {
        position:relative;
        display:flex; flex-direction:column; align-items:center;
        float:left;
        width:100%;
        padding:16px;
        font-size:0; line-height:1.5; font-family:$font_libre_franklin; font-weight:400; color:color(black-600);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-360) {
          font-size:10px;
        }
        @include media-breakpoint-up(xs-375) {
          font-size:calc(10px + 6 * ((100vw - 375px) / 825));
        }
        @include media-breakpoint-up(xl) {
          display:block;
          font-size:16px;
          padding:20px 16px 20px 56px;
        }

        &:before {
          content:'';
          position:absolute; left:0; top:0;
          width:100%; height:3px;
          background-color:transparent;
          opacity:1;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }
        i {
          width:24px; height:24px;
          margin-bottom:8px;
          font-size:24px; line-height:24px; color:color(black-600); text-align:center;

          @include media-breakpoint-up(xl) {
            position:absolute; left:16px; top:16px;
            width:32px; height:32px;
            margin-bottom:0;
            font-size:26px; line-height:32px;
          }
        }
        > span {
          display:none;

          @include media-breakpoint-up(xl) {
            display:inline;
          }
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            color:color(blue-600);
            background-color:color(blue-100);

            &:before {
              background-color:color(blue-200);
            }
            i {
              color:color(blue-600);
            }
          }
        }
      }

      &.active {
        a {
          color:color(blue-600);
          background-color:color(blue-200);

          &:before {
            background-color:color(blue-300);
          }
          i {
            color:color(blue-600);
          }
        }
      }
    }
  }

  .side-menu-bottom {
    position:relative;
    display:none; float:left;
    width:100%;
    z-index:1;
    margin-top:32px;

    @include media-breakpoint-up(xl) {
      display:block;
    }

    li {
      padding:0 32px;

      a {
        position:relative;
        display:block;
        float:left;
        width:auto;
        padding:8px;
        font-size:14px; line-height:1.5; font-family:$font_libre_franklin; font-weight:400; color:color(black-600);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          &:hover {
            color:color(blue-600);
          }
        }

        &:before {
          content:'';
          position:absolute; left:0; bottom:0;
          width:100%; height:2px;
          background-color:transparent;
          opacity:1;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }
      }

      &.social {
        display:flex; align-items:center; justify-content:space-between;
        padding:0 16px; margin-top:64px;

        a {
          position:relative;
          width:28px; height:28px;
          padding:0;

          i {
            position:absolute; left:50%; top:50%;
            width:23px; height:23px;
            font-size:23px; line-height:23px; color:color(black-600); text-align:center;
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }
          &:before {
            content:none;
          }
          @include media-breakpoint-up(lg) {
            &:hover {
              i {
                color:color(blue-600);
              }
            }
          }
        }
      }

      &.active {
        a {
          color:color(blue-600);

          &:before {
            background-color:color(blue-300);
          }
        }
      }
    }
  }
}

.col-side {
  .side-wrapper {
    position:relative;
    float:left;
    width:100%;
    padding:48px 0 0 0;

    @include media-breakpoint-up(lg) {
      position:sticky; top:64px;
      padding:16px 32px 0 16px;

      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:1px; height:100%;
        background-color:color(grey-100);
      }
    }
    @include media-breakpoint-up(xl) {
      top:68px;
    }

    > * {
      &:first-child {
        margin-top:0;
      }
    }

    &.dark-line {
      &:before {
        background-color:color(black-200);
      }
    }
    &.fixed {
      position:relative; top:inherit;
    }
    &.intro {
      @include media-breakpoint-down(lg) {
        top:0!important;
      }
    }

    .side-faq {
      float:left;
      width:100%;

      > p {
        line-height:1.2; font-family:$font_libre_franklin; font-weight:500; font-style:italic;
      }
      .card {
        height:auto;
        margin-top:8px;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(8px + 8 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:16px;
        }

        > a, > div {
          min-height:0;
        }
      }
    }

    .side-nav {
      float:left;
      width:100%;
      margin-top:-16px;

      p {
        line-height:1.2; font-family:$font_libre_franklin; font-weight:500; font-style:italic;
      }

      ul {
        margin-top:24px;
        
        li {
          a {
            display:block; float:left;
            width:auto;
            padding:8px;
            font-size:16px; line-height:1.5; color:color(black-600); font-family:$font_libre_franklin; font-weight:400;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(lg) {
              &:hover {
                color:color(blue-600);
              }
            }
          }
        }
      }
    }
    .side-event {
      display:block!important;
      margin-top:-16px;

      .availability {
        display:none;

        @include media-breakpoint-up(lg) {
          display:flex;
        }
      }
      > p {
        display:none;

        @include media-breakpoint-up(lg) {
          display:block;
        }
      }
    }
    .card-event {
      background-color:color(grey-800);
  
      > a, > div {  
        .content {  
          > div {  
            &.intro {
              .label {  
                margin-top:-10px;

                li {
                  margin:10px 10px 0 0;
                  border:1px solid color(grey-600);
  
                  &.main {
                    border-color:color(blue-200);
  
                    i {
                      color:color(blue-800);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.side-event {
  margin-top:16px;

  @include media-breakpoint-up(lg) {
    display:none;
  }

  .btn {
    width:100%;
    margin-bottom:16px;
  }
  .availability {
    display:flex; flex-wrap:wrap; align-items:center;
    width:100%;
    margin-top:8px;

    span {
      position:relative;
      display:block; float:left;
      width:100%; max-width:calc(100% - 24px);
      padding:8px 12px 8px 48px;
      border:1px solid color(azure);
      border-radius:4px 4px 8px 4px;
      font-size:14px; line-height:1.4; color:color(black); font-family:$font_libre_franklin; font-weight:400;
      background-color:color(azure);
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      @include media-breakpoint-up(lg) {
        /*width:auto;*/ max-width:100%;
      }
      @include media-breakpoint-up(xl) {
        font-size:16px;
      }
      
      &:before {
        content:"\e012";
        position:absolute; left:12px; top:50%;
        display:block;
        width:24px; height:24px;
        font-size:23px; line-height:24px; text-align:center; color:color(black);
        font-family:"Phosphor"!important; speak:never; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none;
        letter-spacing:0; -webkit-font-feature-settings:"liga"; -moz-font-feature-settings:"liga=1"; -moz-font-feature-settings:"liga"; -ms-font-feature-settings:"liga" 1; font-feature-settings:"liga"; -webkit-font-variant-ligatures:discretionary-ligatures; font-variant-ligatures:discretionary-ligatures;
        -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;
        @include prefix(transform, translateY(-50%), webkit moz o ms);
      }

      &.reservation {
        border-color:color(green);
        background-color:color(green);
      }
      &.date {
        border-color:color(green);
        background-color:transparent;
      }
      &.limited {
        border-color:color(yellow);
        background-color:color(yellow);
      }
      &.sold-out {
        border-color:color(red);
        color:color(white);
        background-color:color(red);

        &:before {
          color:color(white)
        }
      }
      &.closed {
        margin-bottom:16px;
        border-color:color(red);
        color:color(red-800);
        background-color:transparent;

        &:before {
          content:"\e3de";
          color:color(red);
        }
      }
      &:has(+ .tooltip-btn) {
        max-width:calc(100% - 24px);
      }
    }
    .tooltip-btn {
      position:relative;
      width:20px; height:20px;
      padding:0; border:0 none;
      margin-left:4px;
      background-color:transparent;

      i {
        position:absolute; left:0; top:0;
        width:20px; height:20px;
        font-size:20px; line-height:20px; text-align:center; color:color(black);
      }
    }
    hr {
      display:block; float:left;
      width:100%; height:0;
      margin:0; padding:0; border:0 none;
      flex-shrink:0;
    }
  }
  > p {
    margin-top:16px;
    font-size:14px; line-height:1.5; font-family:$font_sentient; font-weight:400; color:color(black); text-align:center;
  }
}