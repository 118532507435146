.top-banner {
  position:relative;
  float:left;
  width:100%;
  padding-left:8px; padding-right:8px;
  z-index:1;

  .container {
    position:relative;
    z-index:2;

    .row {
      > div {
        position:relative;
      }
    }
  }

  a {
    position:relative;
    display:block; float:left;
    width:100%;
    z-index:1;

    &.close {
      position:absolute; right:8px; top:0;
      width:40px; height:40px;
      z-index:2;

      i {
        position:absolute; left:50%; top:50%;
        width:20px; height:20px;
        font-size:14px; line-height:20px; text-align:center;
        opacity:.5;
        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          i {
            opacity:1;
          }
        }
      }
    }
  }

  &:before {
    content:'';
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    border:1px solid color(black,.18);
    z-index:1;
  }

  .top-banner-head {
    position:relative;
    float:left;
    width:100%;
    padding:12px 48px 8px 28px;
    z-index:2;

    @include media-breakpoint-up(xl-1440) {
      padding:12px 64px 8px 44px;
    }

    i {
      position:absolute; left:0; top:12px;
      width:20px; height:20px;
      font-size:15px; line-height:20px; text-align:center;

      @include media-breakpoint-up(xl-1440) {
        left:16px;
      }
    }
  }
  .top-banner-content {
    position:relative;
    float:left;
    width:100%;
    padding:8px 0;
    z-index:2;

    @include media-breakpoint-up(xl-1440) {
      padding:8px 16px;
    }

    p {
      font-size:14px;
    }
  }
}
