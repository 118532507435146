.update {
  float:left;
  width:100%;
  padding:0 8px;
  margin-top:16px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(16px + 16 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(lg) {
    padding:0 8px 0 16px;
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:32px;
  }

  span {
    position:relative;
    display:block; float:left;
    width:100%;
    padding:0 0 8px 24px;
    border-bottom:1px solid color(grey-100);
    font-size:14px; line-height:1.5; color:color(black-400); font-family:$font_libre_franklin; font-weight:400;

    i {
      position:absolute; left:0; top:3px;
      width:16px; height:16px;
      font-size:13px; line-height:16px; text-align:center; color:color(black);
    }
  }
}

.image-full {
  display:flex; flex-direction:column; align-items:center;

  picture {
    border-radius:4px 4px 16px 4px;
    overflow:hidden;
  }
  figcaption {
    position:relative;
    display:block; float:left;
    width:auto;
    margin-top:8px; padding-left:16px;
    font-size:14px; line-height:1.5; color:color(black); font-family:$font_sentient; font-weight:400; text-align:left;

    i {
      position:absolute; left:0; top:4px;
      width:12px; height:12px;
      font-size:10px; line-height:12px; text-align:center; color:color(blue);
    }
  }
}
.video-full {
  position:relative;
  float:left;
  width:100%;

  &.r16x9 {
    padding-bottom:56.25%;
  }
  &.r4x3 {
    padding-bottom:75%;
  }

  iframe {
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    border:0 none;
    border-radius:4px 4px 16px 4px;
  }
}
.podcast-full {
  position:relative;
  float:left;
  width:100%;
}

.tag-list {
  float:left;
  width:100%;
  margin-top:16px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(16px + 8 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:24px;
  }

  p {
    font-size:10px; color:color(blue-800); font-weight:400;
  }
  ul {
    li {
      width:auto;
      margin:8px 8px 0 0;

      a, span {
        position:relative;
        display:block; float:left;
        width:auto;
        padding:2px 7px;
        border:1px solid color(blue,.6);
        border-radius:4px 4px 8px 4px;
        font-size:10px; line-height:1.5; color:color(black); font-family:$font_libre_franklin; font-weight:400; letter-spacing:0.6px;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
      }
      a {
        @include media-breakpoint-up(lg) {
          &:hover {
            border-color:color(blue);
          }
        }
      }
    }
  }
}

.nav-anchor {
  display:block; float:left;
  width:100%;
  margin-top:12px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(12px + 18 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:30px;
  }

  ul {
    width:calc(100% + 10px);
    margin-right:-10px;

    li {
      width:auto;
      margin:10px 10px 0 0;

      a {
        position:relative;
        display:block; float:left;
        width:auto;
        padding:2px 8px 2px 24px;
        border-radius:4px 4px 8px 4px; border:1px solid color(blue-200); box-sizing:content-box;
        font-size:16px; line-height:1.5; font-weight:600; font-family:$font_libre_franklin; color:color(blue-800);
        background-color:transparent;

        i {
          position:absolute; left:8px; top:8px;
          width:12px; height:12px;
          font-size:12px; line-height:12px; text-align:center; color:color(blue-800);
        }
      }
    }
  }
}

.anchor {
  display:block; float:left;
  width:100%; height:0;
  margin-top:-92px!important;
}

.filter-nav {
  display:block; float:left;
  width:100%;
  margin-top:-8px;

  ul {
    display:flex; flex-wrap:wrap; justify-content:center;

    li {
      width:auto;
      margin:8px;

      a {
        position:relative;
        display:block; float:left;
        width:auto;
        padding:8px 16px 6px 16px;
        border-radius:0; border-bottom:2px solid color(blue-200);
        font-size:16px; line-height:1.5; color:color(black-600); font-family:$font_libre_franklin;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        i {
          position:absolute; left:16px; top:10px;
          width:20px; height:20px;
          font-size:16px; line-height:20px; color:color(black-600); text-align:center;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }

        @include media-breakpoint-up(lg) {
          &:hover {            
            border-color:color(blue);
            color:color(blue-600);

            i {
              color:color(blue-600);
            }
          }
        }

        &:has(i) {
          padding-left:40px;
        }
      }
      &.active {
        a {          
          border-radius:4px 4px 16px 4px; border-color:color(blue);
          color:color(white);
          background-color:color(blue);

          i {
            color:color(white);
          }
        }
      }
    }
  }

  &.left {
    ul {
      justify-content:flex-start;

      li {
        margin:8px 16px 8px 0;
      }
    }    
  }
}
.filter-content {
  float:left;
  width:100%;
}

.tooltip {
  &.show {
    opacity:1;
  }
  .tooltip-inner {
    padding:4px 8px;
    font-size:14px; line-height:1.5; color:color(white); text-align:left; font-family:$font_sentient;
    background-color:color(black-900);
  }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color:color(black-900)!important;
  }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    border-left-color:color(black-900)!important;
  }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    border-right-color:color(black-900)!important;
  }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    border-bottom-color:color(black-900)!important;
  }
}

.exhibitor-logo {
  display:block; float:left;
  width:100%;
  margin:8px 0;
  border-radius:8px; border:1px solid color(grey-600);
  overflow:hidden;
  
  @include media-breakpoint-up(sm) {
    margin:0;
  }
}