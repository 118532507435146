html {
  scroll-behavior:smooth;
}

body {
  position:relative;
  background-color:color(white-100);
}
.container {
  width:100%; max-width:1456px;
  padding-right:8px; padding-left:8px;

  .row {
    margin-right:-8px; margin-left:-8px;

    > div {
      padding-right:8px; padding-left:8px;
    }
  }
}

.content-wrapper, .main-content {
  float:left;
  width:100%;
  background-color:color(white-100);
}
.content-wrapper {
  position:relative;
  float:none;
  max-width:1440px; min-height:100vh;
  margin:0 auto;
}
.main-content {
  padding-top:56px;

  @include media-breakpoint-up(xl) {
    padding-top:68px;
  }
}
.main-content > .container:first-child > .row:first-child > div:first-child > section:first-child {
  margin-top:0!important;
}

section {
  position:relative;
  float:left;
  width:100%;
  margin-top:16px; margin-right:0;
  padding-left:8px; padding-right:8px;
  z-index:1;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(16px + 16 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl) {
    padding-left:16px;
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:32px;
  }

  &.mt-mini {
    margin-top:8px;
  }
  &.mt-small {
    margin-top:16px;
  }
  &.mt-big {
    margin-top:76px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(76px + 36 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:112px;
    }
  }
  &.mt-more {
    margin-top:32px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(32px + 16 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:48px;
    }
  }
  &.mb-base {
    margin-bottom:16px;

    @include media-breakpoint-up(xs-375) {
      margin-bottom:calc(16px + 16 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-bottom:32px;
    }
  }
}

.overflow-visible { overflow:visible; }
.overflow-hidden { overflow:hidden; }

.front { position:relative; z-index:2; }

ul, ol {
  display:block; float:left;
  width:100%;
  padding:0; margin:0;
  list-style:none;

  li {
    display:block; float:left;
    width:100%;
  }
}

a {
  text-decoration:none!important; color:inherit;
}
*:focus {
  outline:0 none;
}
*:focus-visible {
  outline:4px solid color(focus,.25); outline-offset:0;
  border-radius:0;
}
.close:focus-visible {
  border-radius:6px;
}

picture, figure, img {
  display:block; float:left;
  width:100%; height:auto;
  margin:0;
}

#CookiebotWidget:not(.CookiebotWidget-inactive) {
  bottom:85px!important;
  right:10px!important; left:inherit!important;
}
.mobile-menu-open {
  #CookiebotWidget:not(.CookiebotWidget-inactive) {
    bottom:20px!important;
  }
}