.switch-btn {
  position:relative;
  display:block; float:left;
  width:auto;
  padding:4px 0 4px 40px; border:0 none;
  font-size:14px; line-height:24px; font-family:$font_libre_franklin; font-weight:400; color:color(black);
  background-color:transparent;

  span {
    position:absolute; left:0; top:8px;
    width:32px; height:16px;
    border-radius:16px;
    @include prefix(transition, all .4s ease-out, webkit moz o ms);
    cursor:pointer;
    z-index:3;

    &:before {
      content:'';
      position:absolute; left:3px; top:3px;
      height:10px; width:10px;
      border-radius:50%;
      border:1px solid color(blue);
      background-color:color(white-400);
      @include prefix(transition, all .3s ease-out, webkit moz o ms);
      z-index:2;
    }
    &:after {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      border:1px solid color(blue); border-radius:16px;
      @include prefix(transition, all .3s ease-out, webkit moz o ms);
    }
  }

  &.active {
    span {
      &:before {
        @include prefix(transform, translateX(16px), webkit moz o ms);
      }
      &:after {
        background-color:color(blue);
      }
    }
  }
}

.filter-tag {
  p {
    font-size:14px; line-height:1.5; color:color(black-800); font-family:$font_libre_franklin;
  }
  ul {
    li {
      width:auto;
      margin:8px 8px 0 0;          

      a, span {
        position:relative;
        display:block; float:left;
        width:auto;
        padding:5px 8px 5px 28px;
        border:1px solid color(blue,.6);
        border-radius:2px 2px 8px 2px;
        font-size:16px; line-height:21px; color:color(blue-800); font-family:$font_libre_franklin; font-weight:400; white-space:nowrap;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        i {
          position:absolute; left:8px; top:50%;
          width:16px; height:16px;
          font-size:15px; line-height:16px; text-align:center; color:color(dark-500);
          @include prefix(transform, translateY(-50%), webkit moz o ms);
        }
      }
      a {
        @include media-breakpoint-up(lg) {
          &:hover {
            border-color:color(blue);
          }
        }
      }

      &.active {
        a, span {
          border:1px solid color(blue,1);
          color:color(white);
          background-color:color(blue,1);
  
          i {
            color:color(white);
          }
        }
      }
    }
  }
}

.filter-btn {
  > ul {
    > li {
      width:auto;
      margin:8px 8px 0 0;          

      > a, > span {
        position:relative;
        display:block; float:left;
        width:auto;
        padding:6px 30px 6px 8px;
        border:1px solid color(black-300);
        border-radius:2px 2px 8px 2px;
        font-size:14px; line-height:1.5; color:color(black); font-family:$font_libre_franklin; font-weight:400; white-space:nowrap;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        &:before {
          content:"\e136";
          position:absolute; right:8px; top:50%;
          display:block;
          width:14px; height:14px;
          font-size:12px; line-height:14px; text-align:center; color:color(black);
          font-family:"Phosphor"!important; speak:never; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none;
          letter-spacing:0; -webkit-font-feature-settings:"liga"; -moz-font-feature-settings:"liga=1"; -moz-font-feature-settings:"liga"; -ms-font-feature-settings:"liga" 1; font-feature-settings:"liga"; -webkit-font-variant-ligatures:discretionary-ligatures; font-variant-ligatures:discretionary-ligatures;
          -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;
          @include prefix(transform, translateY(-50%), webkit moz o ms);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }

        &.active {
          padding-right:28px;
          font-weight:700;
        }
        &.all {
          &:before {
            content:"\e222";
            font-size:12px;
          }
        }
      }
      > a {
        @include media-breakpoint-up(lg) {
          &:hover {
            background-color:color(grey-600);
          }
        }
      }
      &.on {
        > a, > span {
          border:1px solid color(blue);
          color:color(white);
          background-color:color(blue);

          &:before {
            color:color(white);
          }
        }
      }
      &.off {
        > a, > span {
          opacity:0.4;
        }
      }
    }
  }
}

.filter-intro {
  display:flex; flex-direction:column; align-items:flex-start;
  width:100%;

  @include media-breakpoint-up(sm) {
    margin-top:16px;
  }

  .switch-btn {
    display:none;

    @include media-breakpoint-up(sm) {
      display:block;
    }
  }

  > div {
    float:left;
    width:100%;

    &.filter-tag {
      display:none;
      margin-top:16px;

      @include media-breakpoint-up(sm) {
        display:block;
      }
    }
    &.filter-btn {
      display:none;
      margin-top:16px;

      @include media-breakpoint-up(sm) {
        display:block;
      }
    }
    &.filter-bottom {
      position:relative;
      display:flex; justify-content:space-between; flex-wrap:wrap; align-items:center;
      margin-top:4px; margin-bottom:-8px;
      padding-bottom:14px;
      border-bottom:1px solid color(grey-600);

      @include media-breakpoint-up(sm) {
        justify-content:flex-end;
        margin-top:14px;
      }

      p {
        width:100%;
        margin-top:12px;
        font-size:14px; line-height:1.5; color:color(black); font-family:$font_libre_franklin; font-weight:600;

        @media (min-width:340px) {
          width:50%;
        }
        @include media-breakpoint-up(sm) {
          width:auto;
          margin-right:auto; margin-top:0;
        }
      }
      a {
        position:relative;
        display:block; float:left;
        width:auto;
        padding-left:20px; margin-top:12px;
        font-size:14px; line-height:1.5; color:color(black-700); font-family:$font_libre_franklin; font-weight:400; white-space:nowrap;

        i {
          position:absolute; left:0; top:50%;
          width:14px; height:14px;
          font-size:12px; line-height:14px; text-align:center; color:color(black-700);
          @include prefix(transform, translateY(-50%), webkit moz o ms);
        }

        @include media-breakpoint-up(sm) {
          margin-left:calc(20px + 20 * ((100vw - 576px) / 864)); margin-top:0;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-left:40px;
        }

        &.filter-mobile {
          padding:6px 32px;
          border:1px solid color(black-300);
          border-radius:2px 2px 8px 2px;
          font-size:16px; line-height:24px; color:color(black); font-family:$font_libre_franklin; font-weight:400; white-space:nowrap;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(sm) {
            display:none;
          }
  
          &:before, &:after {
            position:absolute; top:50%;
            display:block;
            width:16px; height:16px;
            font-size:14px; line-height:16px; text-align:center; color:color(black);
            font-family:"Phosphor"!important; speak:never; font-style:normal; font-weight:normal; font-variant:normal; text-transform:none;
            letter-spacing:0; -webkit-font-feature-settings:"liga"; -moz-font-feature-settings:"liga=1"; -moz-font-feature-settings:"liga"; -ms-font-feature-settings:"liga" 1; font-feature-settings:"liga"; -webkit-font-variant-ligatures:discretionary-ligatures; font-variant-ligatures:discretionary-ligatures;
            -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;
            @include prefix(transform, translateY(-50%), webkit moz o ms);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }
          &:before {
            content:"\e432";
            left:8px;
          }
          &:after {
            content:"\e222";
            right:8px;
          }
        }
      }
    }
  }
}