.quote {
  display:flex; flex-direction:column; justify-content:flex-start; align-items:center;
  margin:0;

  @include media-breakpoint-up(sm) {
    flex-direction:row; justify-content:flex-end; align-items:flex-start;
  }

  figure {
    width:100px;
    margin-bottom:16px;
    border-radius:4px 4px 16px 4px;
    flex-shrink:0;
    overflow:hidden;

    @include media-breakpoint-up(sm) {
      margin-right:16px; margin-bottom:0;
    }
  }
  .quote-content {
    position:relative;
    display:flex; flex-direction:column; align-items:flex-end;
    width:100%;
    padding-left:25px;

    &:before {
      content:'“';
      position:absolute; left:0; top:-2px;
      width:25px;
      font-size:54px; line-height:1.1; font-weight:700; font-style:italic; font-family:$font_cormorant; color:color(blue);
    }

    p {
      padding-top:8px;
      color:color(black-800);
    }
    footer {
      display:block; float:left;
      max-width:83%;

      cite {
        position:relative;
        display:block; float:left;
        width:100%;
        padding-left:24px; margin-top:8px;
        font-size:16px; line-height:1.5; font-weight:400; font-family:$font_libre_franklin; color:color(black-800);

        strong {
          font-weight:700; font-style:normal;
        }

        i {
          position:absolute; left:0; top:4px;
          width:16px; height:16px;
          font-size:12px; line-height:16px; text-align:center; color:color(black-700);
        }
      }
    }
  }

  &.big {
    .quote-content {
      @include media-breakpoint-up(sm) {
        width:calc(100% - 116px); max-width:448px;
      }

      p {
        font-size:27px; line-height:1.1; font-weight:500; font-style:italic; font-family:$font_cormorant;
      }
      footer {
        @include media-breakpoint-up(lg) {
          max-width:78.6%;
        }
      }
    }
  }
  &.med {
    .quote-content {
      p {
        font-size:22px; line-height:1.1; font-weight:500; font-style:italic; font-family:$font_cormorant;
      }
    }
  }
  &.small {
    .quote-content {
      p {
        position:relative;
        padding-left:9px;
        font-size:14px; line-height:1.5; font-weight:400; font-family:$font_sentient;

        &:before {
          content:'';
          position:absolute; left:0; top:0;
          width:1px; height:100%;
          background-color:color(blue-200);
        }
      }
    }
  }
}

.pull-quote {
  display:flex; flex-direction:row; justify-content:flex-end; align-items:flex-start;
  margin:8px 0;

  i {
    width:32px; height:32px;
    margin-right:8px; margin-top:8px;
    font-size:28px; line-height:32px; text-align:center; color:color(blue-800);
    flex-shrink:0;
  }
  p {
    position:relative;
    width:100%;
    padding:8px 0 0 9px;
    line-height:1.1; font-style:italic; font-family:$font_cormorant;

    @include media-breakpoint-up(sm) {
      max-width:472px;
    }

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:1px; height:100%;
      background-color:color(blue-200);
    }
  }

  &.big {
    i {
      width:48px; height:48px;
      font-size:40px; line-height:48px;
    }
    p {
      font-size:44px; font-weight:700;
    }
  }
  &.med {
    p {
      font-size:27px; font-weight:500;
    }
  }
  &.small {
    p {
      font-size:22px; font-weight:500;
    }
  }
}
