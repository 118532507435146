.btn {
  position:relative;
  display:inline-block; float:none;
  border:0 none; border-radius:0;
  width:auto;

  &.btn-base, &.btn-link {
    display:flex; justify-content:center; align-items:center;
    padding:5px 18px;
    border-radius:4px;
    font-size:20px; line-height:1.2; font-family:$font_libre_franklin; font-weight:600; color:color(black); text-align:center;
    background-color:transparent;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);
    z-index:1;

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      border-radius:4px;
      border:1px solid color(black);
      @include prefix(transition, all .3s ease-out, webkit moz o ms);
    }
    i {
      width:20px; height:20px;
      font-size:16px; line-height:20px; text-align:center; color:color(black);
      @include prefix(transition, all .3s ease-out, webkit moz o ms);
    }
    span {
      padding:3px 0;
    }

    &:has(> i:first-child) {
      i {
        margin-left:0; margin-right:4px;
      }
    }
    &:has(> i:last-child) {
      i {
        margin-left:4px; margin-right:0;
      }
    }

    &[class*="bkg-"] {
      overflow:hidden;

      > * {
        position:relative;
        z-index:2;
      }
      &:after {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        background-color:color(black-000);
        opacity:0;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
      }
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        color:color(white);
        background-color:color(black);

        i {
          color:color(white);
        }

        &[class*="bkg-"] {
          &:after {
            opacity:.15;
          }
        }
      }
    }

    &:focus-visible {
      outline:4px solid color(focus,.25); outline-offset:0;
      border-radius:0;
      z-index:2;
    }
  }

  &.btn-link {
    &:before {
      opacity:0;
    }
    @include media-breakpoint-up(lg) {
      &:hover {
        &:before {
          opacity:1;
        }
      }
    }
  }
}
