.row .shelf {
  position:relative;

  > div {
    &.shelf-decoration {
      display:none;
    }
  }

  @include media-breakpoint-up(md) {
    display:flex; justify-content:center; align-items:flex-end; flex-wrap:nowrap;
    margin-top:calc(24px + 8 * ((100vw - 768px) / 672)); padding-bottom:calc(32px + 16 * ((100vw - 768px) / 672));

    &:before {
      content:'';
      position:absolute; left:0; bottom:0;
      width:100%; height:calc(32px + 16 * ((100vw - 768px) / 672));
      border:1px solid color(black-300);
    }

    &.lt {
      &:before {
        border-radius:4px 0 0 16px;
      }
    }
    &.rt {
      &:before {
        border-radius:0 4px 16px 0;
      }
    }

    > div {
      width:auto;
      margin-top:0;
      padding-left:8px; padding-right:8px;
      padding-bottom:8px;

      &.shelf-decoration {
        display:block;
        padding-bottom:0;

        img {
          width:calc(120px + 80 * ((100vw - 768px) / 672));
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    > div {
      padding-left:16px; padding-right:16px;
    }
  }
  @include media-breakpoint-up(xl) {
    &:before {
      left:8px;
      width:calc(100% - 8px);
    }
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:32px; padding-bottom:48px;

    &:before {
      height:48px;
    }
    > div {
      &.shelf-decoration {
        img {
          width:200px;
        }
      }
    }
  }
}