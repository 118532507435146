.search-result-wrapper {
  float:left;
  width:100%;
  margin-top:16px;

  .search-filter {
    display:block; float:left;
    width:100%;

    ul {
      display:flex; justify-content:flex-start;
      float:left;
      width:auto;
      border-bottom:1px solid color(grey-600);

      li {
        width:auto;

        button {
          display:block; float:left;
          width:auto;
          padding:8px 12px;
          border:1px solid transparent; border-bottom:0 none!important; border-radius:4px 4px 0 0;
          font-size:16px; line-height:1.5; color:color(black-600); font-weight:400; font-family:$font_libre_franklin;
          background-color:transparent;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(lg) {
            &:hover {
              color:color(blue-600);
            }
          }
        }

        &.active {
          button {
            border:1px solid color(grey-600);
            color:color(black-900)!important;
            background-color:color(white);
            cursor:default; pointer-events:none;
          }
        }
      }
    }
  }
  .search-result {
    display:block; float:left;
    width:100%;
    margin-top:40px;

    .result {
      display:block; float:left;
      width:100%;

      a {
        display:block; float:left;
        width:100%;

        p {
          margin-bottom:4px;
          font-size:14px; color:color(black-700); font-family:$font_libre_franklin;
        }
        h5, .h5 {
          margin-bottom:4px;
          font-size:20px; line-height:1.2; color:color(blue-500); font-family:$font_libre_franklin; font-weight:500;
        }
        span {
          display:block; float:left;
          width:100%;
          margin-top:4px;
          font-size:10px; line-height:1.5; color:color(black-700); font-family:$font_libre_franklin; letter-spacing:0.6px;
        }
      }

      &.error {
        h5, .h5 {
          margin-bottom:16px;
          color:color(black); font-weight:700;

          strong {
            color:color(red); font-weight:700;
          }
        }
        p {
          font-size:16px; line-height:1.5;
        }
        ul {
          margin-top:-4px; margin-bottom:4px;

          li {
            position:relative;
            padding-left:24px;
            font-size:16px; line-height:1.5; color:color(black-700); font-family:$font_libre_franklin;

            &:before {
              content:'';
              position:absolute; left:10px; top:10px;
              width:4px; height:4px;
              border-radius:50%;
              background-color:color(black);
            }
          }
        }
      }
    }

    > ul {
      li {
        margin-top:32px;

        &:first-child {
          margin-top:0;
        }
      }
    }
  }
}